import React, { useState } from "react";

import PopUp from "../../../components/shared/PopUp/PopUp";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import { Icon } from "../../../components/shared/Icon/Icon";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";

import "./ResetPassword.scss";

interface ResetPasswordProps {
  showPopUp: boolean;
  togglePopUp: (value: boolean) => void;
}

const ResetPassword = (props: ResetPasswordProps) => {
  const { showPopUp, togglePopUp } = props;
  const [email, setEmail] = useState<any>();

  const authCtx = useAuthContext();

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  return (
    <PopUp showPopUp={showPopUp} togglePopUp={togglePopUp}>
      <div className="ResetPassword">
        <Icon
          onClick={() => togglePopUp(false)}
          className="ResetPassword__icon"
          icon="close"
        />
        <h3 className="ResetPassword__title">Reset your password</h3>
        <p className="ResetPassword__subtitle">
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </p>
        <div className="LoginForm__input-group-container">
          <InputField
            type="text"
            className="LoginForm__form-control"
            placeholder="Email"
            name="email"
            value={email}
            onBlur={() => {}}
            onChange={(e) => handleChange(e)}
            onIconClick={() => {}}
          />
        </div>
        <div
          className="ResetPassword__login"
          onClick={() => togglePopUp(false)}
        >
          Return to login
        </div>
        <Button
          isLoading={authCtx.isLoading2}
          label="Continue"
          onClick={() => {
            authCtx.handleResetPassword(email);
            if (!authCtx.isLoading2) togglePopUp(false);
          }}
          className="Button__primary ResetPassword__btn"
          type="button"
          disabled={!email?.includes("@") || email === undefined}
        />
      </div>
    </PopUp>
  );
};

export default ResetPassword;
