import React, { useState, useEffect } from "react";

import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import { useNewDeviceFormik } from "./useNewDeviceFormik";
import { checkIfObjectHasChanged } from "../../../lib/helpers/checkIfObjectHasChanged";
import * as API from "../../../api/Api";
import Geocode from "react-geocode";
import { Icon } from "../../../components/shared/Icon/Icon";
import LocationModal from "../LocationModal/LocationModal";

import "./NewDeviceForm.scss";

interface newDeviceFormProp {
  onClose: () => void;
  refresh: () => void;
  singleDevice: any;
  clearDevice: () => void;
  organizationId: any;
}

const NewDeviceForm = (props: newDeviceFormProp) => {
  const { onClose, refresh, singleDevice, clearDevice, organizationId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [geoLocation, setGeoLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  Geocode.setApiKey(
    process.env.REACT_APP_GEOCODE_API_KEY
      ? process.env.REACT_APP_GEOCODE_API_KEY
      : ""
  );

  const formik: any = useNewDeviceFormik({
    onSubmit: async (values: any) => {
      setIsLoading(true);

      if (singleDevice?.id) {
        const data = {
          name: values.device_name,
          type: values.device_type,
          min_age: parseInt(values.minimum_age),
          location: {
            latitude: geoLocation?.latitude,
            longitude: geoLocation?.longitude,
          },
        };

        API.editDeviceFromOrganization(organizationId, singleDevice?.id, data)
          .then((res) => {
            setIsLoading(false);
            refresh();
            onClose();
            clearDevice();
          })
          .catch((err) => {
            console.error(err);
            setIsLoading(false);
          });
      } else {
        //create new device
        const data = {
          name: values.device_name,
          type: values.device_type,
          min_age: values.minimum_age,
          location: {
            latitude: geoLocation?.latitude,
            longitude: geoLocation?.longitude,
          },
          nfc_required: true,
        };

        API.createDeviceFromOrganization(organizationId, data)
          .then((res) => {
            setIsLoading(false);
            refresh();
            onClose();
          })
          .catch((err) => {
            console.error(err);
            setIsLoading(false);
          });
      }
      //end of create new device
    },
  });

  useEffect(() => {
    if (singleDevice?.id) {
      formik.setFieldValue("device_name", singleDevice.name);
      formik.setFieldValue("device_type", singleDevice.type);
      formik.setFieldValue("minimum_age", singleDevice.min_age);

      setGeoLocation({
        latitude: singleDevice.location.latitude,
        longitude: singleDevice.location.longitude,
      });

      Geocode.fromLatLng(
        singleDevice.location.latitude,
        singleDevice.location.longitude
      ).then((res) => {
        if (res?.plus_code?.compound_code) {
          formik.setFieldValue("location", res.plus_code.compound_code);
        } else {
          formik.setFieldValue("location", res.plus_code.global_code);
        }
      });
    }
  }, []);

  //has form changed?
  useEffect(() => {
    const formValues = {
      name: formik.values.device_name,
      type: formik.values.device_type,
      min_age: parseInt(formik.values.minimum_age),
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude,
    };
    const userValues = {
      name: singleDevice?.name,
      type: singleDevice?.type,
      min_age: parseInt(singleDevice?.min_age),
      latitude: singleDevice?.location?.latitude,
      longitude: singleDevice?.location?.longitude,
    };

    const isChanged = checkIfObjectHasChanged(userValues, formValues);
    if (isChanged) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [
    singleDevice?.name,
    singleDevice?.type,
    singleDevice?.min_age,
    singleDevice?.location?.latitude,
    singleDevice?.location?.longitude,
    geoLocation,
    formik.values,
    formik.values.location,
  ]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <h3 className="NewDeviceForm__title">Device Information</h3>
      <div className="NewDeviceForm__input-group-row">
        <div className="NewDeviceForm__input-group-container">
          <div className="NewDeviceForm__input-label">Device name</div>
          <InputField
            type="text"
            className="NewDeviceForm__form-control"
            name="device_name"
            value={formik.values.device_name}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.device_name && formik.touched.device_name && (
            <div className="NewDeviceForm__invalid-feedback">
              {formik.errors.device_name}
            </div>
          )}
        </div>
        <div className="NewDeviceForm__input-group-container">
          <div className="NewDeviceForm__input-label">Device type</div>
          <InputField
            type="text"
            className="NewDeviceForm__form-control"
            name="device_type"
            value={formik.values.device_type}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.device_type && formik.touched.device_type && (
            <div className="NewDeviceForm__invalid-feedback">
              {formik.errors.device_type}
            </div>
          )}
        </div>
      </div>
      <div className="NewDeviceForm__input-group-container">
        <div className="NewDeviceForm__input-label">Minimum age</div>
        <InputField
          type="number"
          className="NewDeviceForm__form-control"
          name="minimum_age"
          value={formik.values.minimum_age}
          onBlur={(e: any) => formik.handleBlur(e)}
          onChange={(e: any) => formik.handleChange(e)}
          onIconClick={() => {}}
        />
        {formik.errors.minimum_age && formik.touched.minimum_age && (
          <div className="NewDeviceForm__invalid-feedback">
            {formik.errors.minimum_age}
          </div>
        )}
      </div>
      <div className="NewDeviceForm__input-group-container">
        <div className="NewDeviceForm__location-wrapper">
          Add Device Location{" "}
          <div
            className={cs(
              "NewDeviceForm__icon-wrapper",
              openLocationModal && "NewDeviceForm__icon-wrapper--active"
            )}
          >
            <Icon
              onClick={() => setOpenLocationModal(true)}
              icon="map-icon"
              className={cs(`NewDeviceForm__icon`)}
            />
          </div>
        </div>

        <InputField
          type="text"
          className="NewDeviceForm__form-control"
          name="location"
          value={formik.values.location}
          onBlur={() => {}}
          onChange={(e: any) => formik.handleChange(e)}
          onIconClick={() => {}}
          disabled
        />
        {formik.errors.location && formik.touched.location && (
          <div className="NewDeviceForm__invalid-feedback">
            {formik.errors.location}
          </div>
        )}
      </div>
      <div className="NewDeviceForm__buttons">
        <Button
          className={cs(
            "Button__primary--outline",
            `NewDeviceForm__discard-btn`
          )}
          label="Discard"
          onClick={() => {
            onClose();
            clearDevice();
          }}
          type="button"
        />
        <Button
          isLoading={isLoading}
          className={cs("Button", "NewDeviceForm__save-btn")}
          label="Save"
          onClick={() => {}}
          type="submit"
          disabled={!formChanged}
        />
      </div>
      {openLocationModal && (
        <LocationModal
          showPopUp={openLocationModal}
          togglePopUp={() => setOpenLocationModal(!openLocationModal)}
          selectedAddress={formik.values.location}
          selectedDevice={singleDevice}
          sendGeoLocationBack={(geoLoc, address) => {
            setGeoLocation({
              latitude: geoLoc.latitude,
              longitude: geoLoc.longitude,
            });
            formik.setFieldValue("location", address);
          }}
        />
      )}
    </form>
  );
};

export default NewDeviceForm;
