import { useFormik } from "formik";
import * as Yup from "yup";

const PersonalInfoSchema = Yup.object().shape({
  display_name: Yup.string()
    .min(2, "Full name is too short!")
    .max(30, "Full name is too long!")
    .required("Enter your full name"),
  phone_number: Yup.string()
    .nullable()
    .min(7, "Contact number is too short!")
    .max(15, "Contact number is too long!")
    .matches(/^[0-9\+]{8,14}$/, {
      message: "Not valid phone number",
      excludeEmptyString: true,
    }),
});

export const usePersonalInfoFormik = (
  data: any,
  opts: {
    onSubmit?: any;
    initialValues?: any;
  }
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: {
      display_name: data?.display_name || "",
      phone_number: data.phone_number || "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: PersonalInfoSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
