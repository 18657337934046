import { useFormik } from "formik";
import * as Yup from "yup";

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
      "Password needs to have minimum 8 characters, at least one uppercase, one lowercase letter, one number and one special character"
    ),
  confirmPassword: Yup.string()
    .required("Enter confirm password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: () =>
        Yup.string().oneOf(
          [Yup.ref("password")],
          "Passwords do not match. Try again."
        ),
    }),
});

export const useNewPasswordFormik = (opts) => {
  return useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: NewPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
