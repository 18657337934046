import React, { useState } from "react";

import { useSignUpFormik } from "./useSignUpFormik";
import InputField from "../../../components/shared/InputField/InputField";
import RadioButton from "../../../components/shared/RadioButton/RadioButton";
import * as API from "../../../api/Api";
import { useNavigate } from "react-router-dom";

import "./SignUpForm.scss";
import AccountCreated from "../AccountCreated/AccountCreated";

interface signUpProps {
  message: object;
  setMessage: (message: any) => void;
}

const SignUpForm = (props: signUpProps) => {
  const { message, setMessage } = props;
  const [showPassword, setShowPassword] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const navigate = useNavigate();

  const formik = useSignUpFormik({
    onSubmit: async (values: any) => {
      const data = {
        display_name: `${values.first_name} ${values.last_name}`,
        email: values.email,
        password: values.password,
      };
      setIsLoading(true);

      API.register(data)
        .then((res) => {
          if (res?.status === 200) {
            setIsLoading(false);
            setShowPopUp(true);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.email)
            setMessage({
              type: "error",
              title: err?.response?.data?.email[0],
            });
          setIsLoading(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="SignUpForm__input-group-row">
        <div className="SignUpForm__input-group-container">
          <InputField
            type="text"
            className="SignUpForm__form-control"
            placeholder="First name"
            name="first_name"
            value={formik.values.first_name}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.first_name && formik.touched.first_name && (
            <div className="SignUpForm__invalid-feedback">
              Please enter your first name
            </div>
          )}
        </div>
        <div className="SignUpForm__input-group-container">
          <InputField
            type="text"
            className="SignUpForm__form-control"
            placeholder="Last name"
            name="last_name"
            value={formik.values.last_name}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.last_name && formik.touched.last_name && (
            <div className="SignUpForm__invalid-feedback">
              Please enter your last name
            </div>
          )}
        </div>
      </div>
      <div className="SignUpForm__input-group-container">
        <InputField
          type="text"
          className="SignUpForm__form-control"
          placeholder="Email"
          name="email"
          value={formik.values.email}
          onBlur={(e: any) => formik.handleBlur(e)}
          onChange={(e: any) => formik.handleChange(e)}
          onIconClick={() => {}}
        />
        {formik.errors.email && formik.touched.email && (
          <div className="SignUpForm__invalid-feedback">
            Please enter your email
          </div>
        )}
      </div>

      <div className="SignUpForm__input-group-container">
        <div className="SignUpForm__input-wrapper">
          <InputField
            type={showPassword ? "password" : "text"}
            className="SignUpForm__form-control"
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {
              setShowPassword(!showPassword);
            }}
            rightIcon={showPassword ? "eye-off" : "visible-password"}
          />
          {formik.errors.password && formik.touched.password && (
            <div className="SignUpForm__invalid-feedback--password">
              Please enter minimum 8 characters, at least one uppercase, one
              lowercase letter, one number and one special character
            </div>
          )}
        </div>
      </div>

      <div className="SignUpForm__radio-container">
        <RadioButton
          onCheck={() => setIsChecked(!isChecked)}
          label="By proceeding, you agree to the Terms and Conditions"
          checked={isChecked}
        />
      </div>
      <button
        className="Button__primary SignUpForm__btn"
        type="submit"
        disabled={
          formik.errors.first_name !== undefined ||
          formik.errors.last_name !== undefined ||
          formik.errors.email !== undefined ||
          !isChecked ||
          isLoading
        }
      >
        {isLoading ? (
          <div className="SignUpForm__spinner" />
        ) : (
          <>Sign up with email</>
        )}
      </button>
      {showPopUp && (
        <AccountCreated
          email={formik.values.email}
          title="You successfully created your account"
        />
      )}
    </form>
  );
};

export default SignUpForm;
