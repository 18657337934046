import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { Icon } from "../Icon/Icon";
import cs from "classnames";

import "./SelectBox.scss";

export interface ItemsType {
  name: string;
  value?: string | undefined;
}

interface SelectBoxProps {
  items?: ItemsType[];
  className?: string;
  className2?: string;
  style?: any;
  selectedItem: any;
  onItemSelect: (name: string, value: string) => void;
  onClick: () => void;
  labelStyle?: React.CSSProperties;
}

const SelectBox = (props: SelectBoxProps) => {
  const { items, selectedItem, className, className2, style, labelStyle } =
    props;
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const onItemSelect = (name: string, value: any) => {
    props.onItemSelect(name, value);
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div ref={dropDownRef} className={cs("SelectBox", className)} style={style}>
      <div
        onClick={() => setShowItems(!showItems)}
        className="SelectBox__container"
      >
        <p style={labelStyle} className="SelectBox__label">
          {selectedItem?.name}
        </p>
        <Icon
          onClick={() => console.log("clicked")}
          className="SelectBox__icon"
          icon="chevron-down"
        />
      </div>

      <div
        className={cs("SelectBox__items", showItems && "active", className2)}
      >
        {items?.map((item, index) => {
          return (
            <div
              onClick={() => {
                onItemSelect(item.name, item.value);
              }}
              className={`SelectBox__item-container ${
                props.selectedItem.name === item.name ? "activeLabel" : ""
              }`}
              key={index}
            >
              <p className="SelectBox__item">{item.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectBox;
