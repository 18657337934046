// object 1 and object 2 should have same keys in order to compare them.
export const checkIfObjectHasChanged = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }
  return false;
};
