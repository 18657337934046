import React from "react";

import "./LogDetails.scss";
import { Icon } from "../../../components/shared/Icon/Icon";
import { CopyBlock, CodeBlock, atomOneLight } from "react-code-blocks";

const LogDetails = () => {
  const selectedWebhook = JSON.parse(localStorage.getItem("selectedWebhook")!!);
  const selectedLog = JSON.parse(localStorage.getItem("selectedLog")!!);
  const deviceName = JSON.parse(localStorage.getItem("deviceName")!!)
    ? JSON.parse(localStorage.getItem("deviceName")!!)
    : selectedLog?.request_payload?.data?.deviceID;

  return (
    <div className="LogDetails">
      <div className="LogDetails__header">
        <h2 className="LogDetails__header-title">Details</h2>
        <div className="LogDetails__header-subtitle">
          {" "}
          URL: <span>{selectedWebhook?.url}</span>
        </div>
      </div>
      <div className="LogDetails__container">
        <div className="LogDetails__grid">
          <div className="LogDetails__log-header">
            <p>Time</p>
            <p>Time</p>
            <p>Device</p>
            <p>Status</p>
            <p>Duration</p>
          </div>
          <div className="LogDetails__log">
            <div className="LogDetails__time-wrapper">
              <div className="LogDetails__date">
                {" "}
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(selectedLog?.request_payload?.triggerTime))}
              </div>
              <div className="LogDetails__time">
                {new Date(selectedLog?.request_payload?.triggerTime).getHours()}
                :
                {new Date(
                  selectedLog?.request_payload?.triggerTime
                ).getMinutes()}
              </div>
            </div>
            {selectedLog?.response_details?.response_status_code === 200 ||
            selectedLog?.response_details?.response_status_code === 201 ? (
              <div className="LogDetails__status-code">
                <Icon
                  icon="check"
                  onClick={() => {}}
                  className="LogDetails__status-icon"
                />
                {selectedLog?.response_details?.response_status_code}
              </div>
            ) : (
              <div className="LogDetails__status-code">
                <Icon
                  icon="close"
                  onClick={() => {}}
                  className="LogDetails__status-error-icon"
                />
                {selectedLog?.response_details?.response_status_code}
              </div>
            )}

            <div className="LogDetails__device-wrapper">
              <div className="LogDetails__device">{deviceName}</div>
            </div>
            {selectedLog?.response_details?.response_status_code === 200 ? (
              <div className="LogDetails__success">Success</div>
            ) : (
              <div className="LogDetails__fail">Fail</div>
            )}
            <div className="LogDetails__duration">
              {Math.round(
                selectedLog?.response_details?.duration.toFixed(2) * 1000
              )}{" "}
              ms
            </div>
          </div>
          <div className="LogDetails__code-container">
            <div>
              <h4 className="LogDetails__code-title">Request Payload</h4>
              <CopyBlock
                text={
                  JSON.stringify(selectedLog?.request_payload, null, 2)
                    ? JSON.stringify(selectedLog?.request_payload, null, 2)
                    : "No data returned"
                }
                language={"javascript"}
                showLineNumbers={50}
                startingLineNumber={1}
                theme={atomOneLight}
              />
            </div>
            <div>
              <h4 className="LogDetails__code-title">Response Payload</h4>
              {selectedLog?.response_details?.response_status_code === 200 ? (
                <CopyBlock
                  text={
                    JSON.stringify(selectedLog?.data, null, 2)
                      ? JSON.stringify(selectedLog?.data, null, 2)
                      : "No data returned"
                  }
                  language={"javascript"}
                  showLineNumbers={50}
                  startingLineNumber={1}
                  theme={atomOneLight}
                />
              ) : (
                <>
                  {selectedLog?.detail ? (
                    <CopyBlock
                      text={
                        JSON.stringify(selectedLog?.detail, null, 2)
                          ? JSON.stringify(selectedLog?.detail, null, 2)
                          : JSON.stringify("No data returned", null, 2)
                      }
                      language={"javascript"}
                      showLineNumbers={50}
                      startingLineNumber={1}
                      theme={atomOneLight}
                    />
                  ) : (
                    <CodeBlock
                      text={
                        JSON.stringify(selectedLog?.detail, null, 2)
                          ? JSON.stringify(selectedLog?.detail, null, 2)
                          : JSON.stringify("No data returned", null, 2)
                      }
                      language={"javascript"}
                      showLineNumbers={50}
                      startingLineNumber={1}
                      theme={atomOneLight}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogDetails;
