import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";

import { Icon } from "../../../components/shared/Icon/Icon";
import Pagination from "../../../components/shared/Pagination/Pagination";
import { usePaginationContext } from "../../../lib/context/PaginationContext/PaginationContext";

import "./WebhookLogs.scss";
import WebhooksLogLoader from "../../../components/shared/SkeletonLoader/WebhooksLogLoader";

const WebhookLogs = () => {
  const [allLogs, setAllLogs] = useState([]);
  const [deviceIds, setDeviceIds] = useState<any>([]);
  const [devices, setDevices] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedWebhook = JSON.parse(localStorage.getItem("selectedWebhook")!!);
  const authCtx = useAuthContext();
  const navigate = useNavigate();
  const paginationCtx = usePaginationContext();

  let filtered: any;

  const webhookLogsPath = `organizations/${authCtx.selectedOrganization?.id}/webhooks/${selectedWebhook?.id}/logs/?limit=${paginationCtx.selectedNumber}&offset=${paginationCtx.offsetNumber}`;

  useEffect(() => {
    setIsLoading(true);
    API.getWebhooksLogs(webhookLogsPath)
      .then((res) => {
        if (res.status === 200) {
          setAllLogs(res.data);
          //get all the device ids from logs
          res.data.map((item: any) =>
            setDeviceIds((prev: any) =>
              !prev?.includes(item.request_payload.data?.deviceID)
                ? [...prev, item.request_payload.data?.deviceID]
                : [...prev]
            )
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [webhookLogsPath]);

  useEffect(() => {
    if (deviceIds?.length !== 0) {
      deviceIds.map((item: any) => {
        API.getDeviceFromOrganization(
          authCtx.selectedOrganization?.id,
          item
        ).then((res) => {
          setDevices((prev: any) => [
            ...prev,
            {
              id: item,
              name: res.data?.name,
            },
          ]);
        });
      });
    }
  }, [deviceIds]);

  return (
    <div className="WebhookLogs">
      <div className="WebhookLogs__header">
        <h2 className="WebhookLogs__header-title">
          {" "}
          {selectedWebhook?.name} logs{" "}
        </h2>
      </div>
      <div className="WebhookLogs__container">
        {isLoading ? (
          <WebhooksLogLoader />
        ) : (
          <>
            {allLogs.length !== 0 ? (
              <div className="WebhookLogs__grid">
                <div className="WebhookLogs__log-header">
                  <p>Time</p>
                  <p>Status Code</p>
                  <p>Device</p>
                  <p>Status</p>
                  <p>Duration</p>
                </div>
                {allLogs?.map((item: any, index) => (
                  <div
                    className="WebhookLogs__log"
                    key={index}
                    onClick={() => {
                      localStorage.setItem("selectedLog", JSON.stringify(item));
                      if (filtered) {
                        localStorage.setItem(
                          "deviceName",
                          JSON.stringify(filtered)
                        );
                      }
                      navigate("/log-details");
                    }}
                  >
                    <div className="WebhookLogs__time-wrapper">
                      <div className="WebhookLogs__date">
                        {" "}
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(item?.request_payload?.triggerTime))}
                      </div>
                      <div className="WebhookLogs__time">
                        {new Date(
                          item?.request_payload?.triggerTime
                        ).getHours()}
                        :
                        {new Date(
                          item?.request_payload?.triggerTime
                        ).getMinutes()}
                      </div>
                    </div>
                    {item?.response_details?.response_status_code === 200 ||
                    item?.response_details?.response_status_code === 201 ? (
                      <div className="WebhookLogs__status-code">
                        <Icon
                          icon="check"
                          onClick={() => {}}
                          className="WebhookLogs__status-icon"
                        />
                        {item?.response_details?.response_status_code}
                      </div>
                    ) : (
                      <div className="WebhookLogs__status-code">
                        <Icon
                          icon="close"
                          onClick={() => {}}
                          className="WebhookLogs__status-error-icon"
                        />
                        {item?.response_details?.response_status_code}
                      </div>
                    )}

                    <div className="WebhookLogs__device-wrapper">
                      {item?.request_payload?.data?.deviceID ? (
                        <div className="WebhookLogs__device">
                          {devices.find(
                            (device: any) =>
                              device.id ===
                              item?.request_payload?.data?.deviceID
                          )
                            ? (filtered = devices.find(
                                (device: any) =>
                                  device.id ===
                                  item?.request_payload?.data?.deviceID
                              )?.name)
                            : item?.request_payload?.data?.deviceID}
                        </div>
                      ) : null}
                    </div>
                    {item?.response_details?.response_status_code === 200 ||
                    item?.response_details?.response_status_code === 201 ? (
                      <div className="WebhookLogs__success"> Success</div>
                    ) : (
                      <div className="WebhookLogs__fail">Fail</div>
                    )}
                    <div className="WebhookLogs__duration">
                      {Math.round(
                        item?.response_details?.duration.toFixed(2) * 1000
                      )}{" "}
                      ms
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="WebhookLogs__no-content">
                {" "}
                No{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {selectedWebhook?.name}
                </span>{" "}
                logs found!{" "}
              </div>
            )}
          </>
        )}
      </div>
      <div className="WebhookLogs__pagination">
        <Pagination />
      </div>
    </div>
  );
};

export default WebhookLogs;
