import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { Icon } from "../Icon/Icon";
import cs from "classnames";

import "./MultipleSelectBox.scss";

export interface ItemsType {
  name: string;
  id?: string | undefined;
}

interface SelectBoxProps {
  items?: ItemsType[];
  setSelectedDevices: any;
  className?: string;
  className2?: string;
  style?: any;
  selectedItem: any;
  onItemSelect: (name: string, value: string) => void;
  onClick: () => void;
  labelStyle?: React.CSSProperties;
}

const MultipleSelectBox = (props: SelectBoxProps) => {
  const {
    items,
    selectedItem,
    className,
    className2,
    style,
    labelStyle,
    setSelectedDevices,
  } = props;
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const onItemSelect = (name: string, value: any) => {
    props.onItemSelect(name, value);
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const handleRemoveItem = (value: string) => {
    let filtered = selectedItem.filter((item: any) => item.id !== value);
    setSelectedDevices(filtered);
  };

  return (
    <div
      ref={dropDownRef}
      className={cs("MultipleSelectBox", className)}
      style={style}
    >
      <div
        onClick={() => setShowItems(!showItems)}
        className="MultipleSelectBox__container"
      >
        <div className="MultipleSelectBox__container2">
          {selectedItem?.map((item: any) => (
            <div
              style={labelStyle}
              className="MultipleSelectBox__label-wrapper"
            >
              <div className="MultipleSelectBox__label"> {item?.name} </div>
              <Icon
                onClick={() => {
                  handleRemoveItem(item?.id);
                }}
                className="MultipleSelectBox__label-icon"
                icon="close"
              />
            </div>
          ))}
        </div>
        <Icon
          onClick={() => console.log("clicked")}
          className="MultipleSelectBox__icon"
          icon="chevron-down"
        />
      </div>

      <div
        className={cs(
          "MultipleSelectBox__items",
          showItems && "active",
          className2
        )}
      >
        {items &&
          items?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  onItemSelect(item?.name, item?.id);
                }}
                className={`MultipleSelectBox__item-container ${
                  props.selectedItem?.name === item?.name ? "activeLabel" : ""
                }`}
                key={index}
              >
                <p className="MultipleSelectBox__item">{item?.name}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MultipleSelectBox;
