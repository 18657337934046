import { apiRequest } from "./Api";

export const getSecureKey = async (organizationId: string) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/secure_key/`
  );
};

export const getSecureKeyRoll = async (organizationId: string) => {
  return apiRequest<any, any>(
    "post",
    `organizations/${organizationId}/secure_key/roll/`
  );
};

export const getSecureKeyLogs = async (path: string) => {
  return apiRequest<any, any>("get", path);
};
