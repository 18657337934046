import { useFormik } from "formik";
import * as Yup from "yup";

const CreateOrganizationSchema: any = Yup.object().shape({
  name: Yup.string().required("Enter your organization name"),
  category: Yup.string().required("Enter your organization category"),
  city: Yup.string().required("Enter your city"),
  address: Yup.string().required("Enter your address"),
});

export const useCreateOrganizationFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    initialValues: {
      name: "",
      category: "",
      city: "",
      address: "",
      billing_type: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CreateOrganizationSchema,
    onSubmit: async (values: any, formikHelpers: any) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
