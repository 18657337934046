import React, { useRef, useState } from "react";

import "./DropDownWithImg.scss";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { Icon } from "../Icon/Icon";

let cs = require("classnames");

interface DropDownWithImgProps {
  items: any;
  className?: string;
  className2?: string;
  style?: any;
  selectedItem: any;
  onItemSelect: (item: any) => void;
  onClick?: () => void;
  placeholder?: string;
}

const DropDownWithImg = (props: DropDownWithImgProps) => {
  const { items, selectedItem, className, className2, style, placeholder } =
    props;
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const onItemSelect = (item: any) => {
    props.onItemSelect({ name: item?.name, flag: item.flag });
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div
      ref={dropDownRef}
      className={cs("DropDownWithImg", className)}
      style={style}
    >
      <div className="DropDownWithImg__container">
        <div className="DropDownWithImg__input-flag">{selectedItem?.flag}</div>
        <input
          onClick={() => setShowItems(!showItems)}
          value={selectedItem?.name}
          className="DropDownWithImg__input"
          onChange={(e) => {
            props.onItemSelect({ name: e.target.value, flag: "" });
            setShowItems(true);
          }}
          placeholder={placeholder}
        />

        <div></div>
        <Icon
          className="DropDownWithImg__icon"
          onClick={() => setShowItems(!showItems)}
          icon="chevron-down"
        />
      </div>

      <div
        className={cs(
          "DropDownWithImg__items",
          showItems && "active",
          className2
        )}
      >
        {items
          ?.filter((item: any) => {
            if (selectedItem.name === "") {
              return item;
            } else if (
              item?.name
                ?.toLowerCase()
                .startsWith(selectedItem?.name.toLowerCase())
            ) {
              return item;
            }
          })
          .map((item: any) => {
            return (
              <div
                onClick={() =>
                  onItemSelect({ name: item.name, flag: item.flag })
                }
                className={`DropDownWithImg__item-container ${
                  props.selectedItem?.name === item?.name ? "activeLabel" : ""
                }`}
              >
                <div className="DropDownWithImg__item">
                  <span className="DropDownWithImg__image">{item?.flag}</span>
                  {item?.name}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DropDownWithImg;
