import { createContext, useContext } from "react";

export interface PaginationContextTypes {
  selectedPage: number;
  offsetNumber: number;
  pagesArraySliced: number[];
  selectedShowPage: { name: string };
  selectedNumber: number;
  firstPage: () => void;
  endPage: () => void;
  prevPage: () => void;
  nextPage: () => void;
  onPageSelect: (item: any) => void;
  onItemSelect: (name: string) => void;
  setSelectedPage: (item: number) => void;
}

const PaginationContextValues: PaginationContextTypes = {
  selectedPage: 1,
  offsetNumber: 10,
  pagesArraySliced: [],
  selectedShowPage: { name: "10" },
  selectedNumber: 10,
  firstPage: () => {},
  endPage: () => {},
  prevPage: () => {},
  nextPage: () => {},
  onPageSelect: () => {},
  onItemSelect: () => {},
  setSelectedPage: () => {},
};

export const PaginationContext = createContext<PaginationContextTypes>(
  PaginationContextValues
);
export const usePaginationContext = () => useContext(PaginationContext);
