import { IconDefinition } from "../shared/Icon/Icon.generated";

interface menuItemsTypes {
  id?: string;
  label?: string;
  icon?: IconDefinition;
  path?: string;
  hasChildItems?: boolean;
  childItems?: any;
  isHeader?: boolean;
}

export const menuItems: menuItemsTypes[] = [
  {
    label: "Menu",
    isHeader: true,
  },
  {
    id: "dashboard",
    label: "Dashboard",
    icon: "home",
    path: "/",
  },
  {
    id: "webhooks",
    label: "Webhooks",
    icon: "webhook",
    path: "/webhooks",
    hasChildItems: false,
  },
  {
    id: "devices",
    label: "Devices",
    icon: "devices_icon",
    path: "/devices",
    hasChildItems: false,
  },
  {
    id: "secure key",
    label: "Secure Key",
    icon: "key",
    path: "/secure-key",
    hasChildItems: false,
  },
];
