import React, { useState } from "react";

import { AlertModalContext, AlertModalContextType } from "./AlertModalContext";

interface AlertModalContextProps {
  children: React.ReactNode | null;
}

export const AlertModalContextProvider = (props: AlertModalContextProps) => {
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");

  const openMessageModal = (type: string, message: string) => {
    setType(type);
    setMessage(message);

    setTimeout(() => {
      setType("");
      setMessage("");
    }, 7000);
  };

  const closeMessageModal = () => {
    setType("");
    setMessage("");
  };

  const context: AlertModalContextType = {
    type,
    message,
    openMessageModal,
    closeMessageModal,
  };

  return (
    <AlertModalContext.Provider value={context}>
      {props.children}
    </AlertModalContext.Provider>
  );
};
