import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import createdAccount from "../../../assets/images/created-account.svg";
import { Icon } from "../../../components/shared/Icon/Icon";
import Button from "../../../components/shared/Button/Button";
import * as API from "../../../api/Api";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import AuthAlert from "../../../components/shared/AuthAlert/AuthAlert";

import "./AccountCreated.scss";

interface AccountCreatedProps {
  email: string;
  title?: string;
  resend?: boolean;
}

const AccountCreated = (props: AccountCreatedProps) => {
  const { email, title, resend } = props;

  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [message, setMessage] = useState<any>({
    type: "info",
    title: "",
  });

  const authCtx = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (resend && isButtonDisabled) {
      setSeconds(59);
    }
  }, []);

  useEffect(() => {
    if (seconds === 60) {
      return;
    }

    if (seconds < 60) {
      setIsButtonDisabled(true);

      seconds > 0 && setTimeout(() => setSeconds(seconds - 1), 1000);
    }

    if (seconds === 0) {
      setIsButtonDisabled(false);
      return;
    }
  }, [seconds]);

  const handleResendVerificationEmail = () => {
    setIsLoading(true);

    if (resend) {
      API.resendEmailVerification(email)
        .then((res) => {
          setSeconds(59);
          setMessage({
            type: "success",
            title: res?.data?.detail,
          });
          setTimeout(
            () =>
              setMessage({
                type: "info",
                title: "",
              }),
            4000
          );

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error, "err");
        });
    }
  };

  return (
    <div className="AccountCreated">
      <div className="AccountCreated__container">
        {message?.title && (
          <AuthAlert title={message?.title} type={message?.type} />
        )}
        <div className="AccountCreated__wrapper">
          <div className="NotificationModal">
            <Icon
              onClick={() => {
                navigate("/login");
                authCtx.setOpenPopUp(false);
              }}
              className="NotificationModal__icon"
              icon="close"
            />
            <img
              src={createdAccount}
              className="NotificationModal__image"
              alt=""
            />
            <h1 className="NotificationModal__title">{title}</h1>
            <p className="NotificationModal__subtitle">
              We've sent an email to <strong>{email}</strong> to verify your
              email address and activate your account.
            </p>
            {resend ? (
              <Button
                label="Resend email"
                onClick={() => handleResendVerificationEmail()}
                className="Button__primary ResetPassword__btn"
                type="button"
                disabled={isButtonDisabled}
                isLoading={isLoading}
              />
            ) : (
              <Button
                label="Back to Login"
                onClick={() => navigate("/login")}
                className="Button__primary ResetPassword__btn"
                type="button"
              />
            )}
            {resend && seconds !== 0 && (
              <div className="AccountCreated__message">
                <span>Didn't receive email?</span>{" "}
                <strong>Resend it in {seconds} seconds.</strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCreated;
