import React from "react";

import { Icon } from "../Icon/Icon";

import { useSidebarContext } from "../../../lib/context/SidebarContext/SidebarContext";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useAlertModalContext } from "../../../lib/context/AlertModalContext/AlertModalContext";
import cs from "classnames";

import "./AlertModal.scss";

const AlertModal = () => {
  const isMobile = useResponsiveDimensions().isMobile;
  const isSidebarOpen = useSidebarContext().isSidebarOpen;
  const type = useAlertModalContext().type;
  const message = useAlertModalContext().message;
  const closeMessageModal = useAlertModalContext().closeMessageModal;

  return (
    <div
      className={cs(
        "AlertModal",
        `AlertModal__${type}`,
        isMobile && "AlertModal__isMobile",
        isSidebarOpen && !isMobile && "AlertModal__isSidebarOpen"
      )}
    >
      <div className="AlertModal__text">{message}</div>
      <Icon
        className="AlertModal__icon"
        onClick={() => {
          closeMessageModal();
        }}
        icon="close"
      />
    </div>
  );
};

export default AlertModal;
