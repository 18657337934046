import { useFormik } from "formik";
import * as Yup from "yup";

const LoginSchema: any = Yup.object().shape({
  email: Yup.string()
    .required("Enter your email")
    .email("Enter a valid email address"),
  password: Yup.string().required("Enter your password"),
});

export const useLoginFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    initialValues: {
      email: "",
      password: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values: any, formikHelpers: any) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
