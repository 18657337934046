import React from "react";
import ContentLoader from "react-content-loader";

const ChartLoader = (props) => (
  <ContentLoader
    speed={4}
    width={1000}
    height={500}
    viewBox="0 0 1000 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="400" y="28" rx="4" ry="4" width="200" height="16"></rect>
    <rect x="4" y="75" rx="4" ry="4" width="4" height="380"></rect>
    <rect x="42" y="100" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="140" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="180" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="220" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="260" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="300" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="340" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="380" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="42" y="420" rx="4" ry="4" width="900" height="20"></rect>
    <rect x="-32" y="480" rx="4" ry="4" width="1000" height="4"></rect>
  </ContentLoader>
);

export default ChartLoader;
