import React from "react";
import ContentLoader from "react-content-loader";

const DeviceLoader = (props) => (
  <ContentLoader
    speed={4}
    width={500}
    height={350}
    viewBox="0 0 500 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: "100%" }}
  >
    <circle cx="240" cy="70" r="30" />
    <rect x="160" y="130" rx="4" ry="4" width="160" height="20"></rect>
    <rect x="135" y="160" rx="4" ry="4" width="210" height="20"></rect>
    <rect x="135" y="190" rx="4" ry="4" width="210" height="20"></rect>
    <rect x="160" y="220" rx="4" ry="4" width="160" height="20"></rect>
    <rect x="190" y="260" rx="6" ry="6" width="100" height="45"></rect>
  </ContentLoader>
);

export default DeviceLoader;
