import React, { useCallback, useEffect, useRef, useState } from "react";

import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import PopUp from "../../../components/shared/PopUp/PopUp";
import Button from "../../../components/shared/Button/Button";
import { Icon } from "../../../components/shared/Icon/Icon";

import MapGL, { Marker } from "react-map-gl";
// @ts-ignore
import Geocoder from "react-map-gl-geocoder";

import Geocode from "react-geocode";

import "./LocationModal.scss";

interface LocationModalProps {
  showPopUp?: boolean;
  togglePopUp?: any;
  selectedAddress: string;
  selectedDevice?: any;
  sendGeoLocationBack: (geoLoc: any, address: string) => void;
}

const LocationModal = (props: LocationModalProps) => {
  const {
    showPopUp,
    togglePopUp,
    selectedAddress,
    selectedDevice,
    sendGeoLocationBack,
  } = props;
  const [viewport, setViewport] = useState({
    longitude: selectedDevice?.location?.longitude
      ? selectedDevice.location.longitude
      : 21.166191,
    latitude: selectedDevice?.location?.latitude
      ? selectedDevice.location.latitude
      : 42.667542,
    zoom: selectedDevice?.location?.longitude ? 12 : 6,
  });
  const [marker, setMarker] = useState({
    longitude: selectedDevice?.location?.longitude
      ? selectedDevice.location.longitude
      : undefined,
    latitude: selectedDevice?.location?.latitude
      ? selectedDevice.location.latitude
      : undefined,
  });
  const [inputValue, setInputValue] = useState("");

  const geocoderContainerRef = useRef(null);
  const mapRef = useRef(null);

  const handleViewportChange = useCallback(
    (newViewport: any) => setViewport(newViewport),
    []
  );

  const handleGeocoderViewportChange = useCallback(
    (newViewport: any) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      setMarker({
        longitude: newViewport.longitude,
        latitude: newViewport.latitude,
      });

      Geocode.fromLatLng(newViewport.latitude, newViewport.longitude).then(
        (res) => {
          if (res?.plus_code?.compound_code) {
            setInputValue(res.plus_code.compound_code);
          } else {
            setInputValue(res.plus_code.global_code);
          }
        }
      );

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  const onMapClick = (event: any) => {
    setMarker({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });

    Geocode.fromLatLng(event.lngLat[1], event.lngLat[0]).then((res) => {
      if (res?.plus_code?.compound_code) {
        setInputValue(res.plus_code.compound_code);
      } else {
        setInputValue(res.plus_code.global_code);
      }
    });
  };

  useEffect(() => {
    setInputValue(selectedAddress);
  }, [selectedAddress]);

  return (
    <PopUp showPopUp={showPopUp} togglePopUp={togglePopUp}>
      <div className="LocationModal">
        <Icon
          onClick={() => togglePopUp(false)}
          className="LocationModal__icon"
          icon="close"
        />
        <div className="LocationModal__container">
          <div
            ref={geocoderContainerRef}
            style={{ position: "absolute", top: 20, left: 20, zIndex: 1 }}
          />

          <MapGL
            ref={mapRef}
            {...viewport}
            width="100%"
            height="100%"
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            onClick={(event) => {
              onMapClick(event);
            }}
          >
            {marker.longitude !== undefined && (
              <Marker longitude={marker.longitude} latitude={marker.latitude}>
                <Icon
                  style={{
                    color: "#4568F2",
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                    top: "-30px",
                    left: "-15px",
                  }}
                  icon="marker"
                />
              </Marker>
            )}

            <Geocoder
              mapRef={mapRef}
              containerRef={geocoderContainerRef}
              onViewportChange={handleGeocoderViewportChange}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              position="top-left"
              minLength={5}
              reverseGeocode={true}
              inputValue={inputValue}
            />
          </MapGL>
        </div>

        <div className="LocationModal__buttons-wrapper">
          <Button
            className="Button__primary--outline"
            label="Discard"
            onClick={() => {
              togglePopUp(false);
            }}
            type="button"
          />
          <Button
            label="Save Location"
            onClick={() => {
              sendGeoLocationBack(marker, inputValue);
              togglePopUp(false);
            }}
            className="Button__primary LocationModal__btn"
            type="button"
            disabled={marker.longitude === undefined}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default LocationModal;
