import { useFormik } from "formik";
import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "First name is too short!")
    .max(30, "First name is too long!")
    .required("Enter your first name"),
  last_name: Yup.string()
    .min(2, "Last name is too short!")
    .max(30, "Last name is too long!")
    .required("Enter your last name"),
  email: Yup.string()
    .required("Enter your email")
    .email("Enter a valid email address"),
  password: Yup.string()
    .required("Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
      "Password needs to have minimum 8 characters, at least one uppercase, one lowercase letter, one number and one special character"
    ),
});

export const useSignUpFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SignUpSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
