import React, { useState } from "react";

import { useLoginFormik } from "./useLoginFormik";
import Button from "../../../components/shared/Button/Button";
import InputField from "../../../components/shared/InputField/InputField";
import ResetPassword from "../ResetPassword/ResetPassword";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import RadioButton from "../../../components/shared/RadioButton/RadioButton";
import AccountCreated from "../../SignUp/AccountCreated/AccountCreated";

import "./LoginForm.scss";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const isTablet = useResponsiveDimensions().isTablet;

  const authCtx = useAuthContext();

  const formik = useLoginFormik({
    onSubmit: (values: any) => {
      const data = {
        email: values.email,
        password: values.password,
      };
      authCtx.handleLogin(data);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="LoginForm__input-group-container">
        <InputField
          type="text"
          className="LoginForm__form-control"
          placeholder="Email"
          name="email"
          value={formik.values.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          onIconClick={() => {}}
        />
        {formik.errors.email && formik.touched.email && (
          <div className="LoginForm__invalid-feedback">
            Please enter your email
          </div>
        )}
      </div>

      <div className="LoginForm__input-group-container">
        <div className="LoginForm__input-wrapper">
          <InputField
            type={showPassword ? "password" : "text"}
            className="LoginForm__form-control"
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onIconClick={() => {
              setShowPassword(!showPassword);
            }}
            rightIcon={showPassword ? "eye-off" : "visible-password"}
          />
          {formik.errors.password && formik.touched.password && (
            <div className="LoginForm__invalid-feedback">
              Please enter your password
            </div>
          )}
        </div>
      </div>
      <div className="LoginForm__forgot-password-container">
        <RadioButton
          onCheck={() => setIsChecked(!isChecked)}
          label="Remember me"
          checked={isChecked}
        />
        <div
          className="LoginForm__forgot-password"
          onClick={() => setShowPopUp(true)}
        >
          Forgot password?
        </div>
      </div>

      {showPopUp && (
        <ResetPassword
          showPopUp={showPopUp}
          togglePopUp={() => setShowPopUp(!showPopUp)}
        />
      )}

      <Button
        isLoading={authCtx.isLoading}
        label={isTablet ? "Log in" : "Log in with email"}
        onClick={() => {}}
        className="Button__primary LoginForm__btn"
        type="submit"
        disabled={
          formik.values.email.length === 0 ||
          formik.values.password.length === 0
        }
      />
      {authCtx.openPopUp && (
        <AccountCreated
          email={formik.values.email}
          title="You need to verify email to continue!"
          resend
        />
      )}
    </form>
  );
};

export default LoginForm;
