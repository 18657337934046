import React, { useEffect, useState } from "react";
import VerificationCountChart from "./VerificationCountChart/VerificationCountChart";
import * as API from "../../api/Api";

import "./Dashboard.scss";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({
    name: "",
    value: "",
  });

  const selectedOrganization2 = JSON.parse(
    localStorage.getItem("selectedOrganization")!!
  );

  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    setIsLoading(true);
    API.getAllDevicesFromOrganization(selectedOrganization2?.id)
      .then((res) => {
        setSelectedDevice({
          name: res?.data[0].name,
          value: res?.data[0].id,
        });
        res?.data.map((item: any) => {
          let filtered = allDevices.filter(
            (device: any) => device?.value === item?.id
          );

          if (filtered.length === 0) {
            let arrayData = {
              name: item.name,
              value: item.id,
            };
            setAllDevices((prev: any) => prev.concat(arrayData));
          }
          setIsLoading(false);
        });
      })
      .catch((err) => {
        console.error(err, "err");
        setIsLoading(false);
      });
  }, [selectedOrganization2?.id, accessToken]);

  return (
    <div className="Dashboard">
      <p className="Dashboard__title">Analytics</p>
      <VerificationCountChart
        allDevices={allDevices}
        setSelectedDevice={setSelectedDevice}
        selectedDevice={selectedDevice}
        isLoading2={isLoading}
      />
    </div>
  );
};

export default Dashboard;
