import React from "react";

import PopUp from "../../../components/shared/PopUp/PopUp";
import Button from "../../../components/shared/Button/Button";
import { Icon } from "../../../components/shared/Icon/Icon";

import "./NotificationModal.scss";

interface NotificationModalProps {
  showPopUp?: boolean;
  togglePopUp?: any;
  image?: any;
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  handleClick?: any;
  withPopUp?: boolean;
}

const NotificationModal = (props: NotificationModalProps) => {
  const {
    showPopUp,
    togglePopUp,
    image,
    buttonLabel,
    title,
    subtitle,
    handleClick,
    withPopUp,
  } = props;

  return (
    <>
      {withPopUp ? (
        <PopUp showPopUp={showPopUp} togglePopUp={togglePopUp}>
          <div className="NotificationModal">
            <Icon
              onClick={() => togglePopUp(false)}
              className="NotificationModal__icon"
              icon="close"
            />
            <img src={image} className="NotificationModal__image" alt="" />
            <h1 className="NotificationModal__title">{title}</h1>
            <p className="NotificationModal__subtitle">{subtitle}</p>
            <Button
              label={buttonLabel}
              onClick={() => handleClick()}
              className="Button__primary ResetPassword__btn"
              type="button"
            />
          </div>
        </PopUp>
      ) : (
        <div className="NotificationModal">
          <img src={image} className="NotificationModal__image" alt="" />
          <h1 className="NotificationModal__title">{title}</h1>
          <p className="NotificationModal__subtitle">{subtitle}</p>
          <Button
            label={buttonLabel}
            onClick={() => handleClick()}
            className="Button__primary ResetPassword__btn"
            type="button"
          />
        </div>
      )}
    </>
  );
};

export default NotificationModal;
