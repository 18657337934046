import React, { useState, useEffect } from "react";
import { Icon } from "../../components/shared/Icon/Icon";
import PersonalInfoForm from "./PersonalInfoForm/PersonalInfoForm";
import Button from "../../components/shared/Button/Button";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import PopUp from "../../components/shared/PopUp/PopUp";
import { useAlertModalContext } from "../../lib/context/AlertModalContext/AlertModalContext";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import * as API from "../../api/Api";
import cs from "classnames";
import MessageModal from "../../components/shared/MessageModal/MessageModal";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

import "./Profile.scss";

const Profile = () => {
  const [state, setState] = useState({
    item: "user",
    active: true,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  const authCtx = useAuthContext();
  const openMessageModal = useAlertModalContext().openMessageModal;
  const [showModal, setShowModal] = useState(false);
  let accessToken = localStorage.getItem("access_token")!!;
  const location = useLocation();

  //checking if refresh token has expired, if true, logout user
  useEffect(() => {
    const refreshToken: any = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");

    if (accessToken !== null) {
      const tokenInfo: any = jwt_decode(refreshToken);
      const isExpired = dayjs.unix(tokenInfo.exp).diff(dayjs()) < 1;
      if (isExpired) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }, [location]);

  const handleUploadImage = (files: any) => {
    if (files[0] !== undefined) {
      setIsLoading(true);

      let formData = new FormData();
      formData.append("profile_image", files[0]);

      Axios.put(
        `${process.env.REACT_APP_API_URL}/users/upload_profile_image/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            API.updateUserProfile(res.data.profile)
              .then((res) => {
                if (res?.status === 200) {
                  authCtx.getUserData();
                  openMessageModal(
                    "success",
                    `Success, profile has been updated!`
                  );
                  setIsLoading(false);
                  setShowPopUp(false);
                }
                setIsLoading(false);
              })
              .catch((err) => {
                openMessageModal(
                  "error",
                  `${
                    err?.response?.data?.error
                      ? err?.response?.data?.error
                      : "An error has occurred, please try again!"
                  }`
                );
                setIsLoading(false);
              });

            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setFileUploadError(error.message);
          setTimeout(() => {
            setFileUploadError("");
          }, 7000);
        });
    }
  };

  const handleImageDelete = (imageUrl: string) => {
    API.updateUserProfile({ picture_url: "" })
      .then((res) => {
        authCtx.getUserData();
        setShowModal(false);
        openMessageModal("success", `Success, profile has been updated!`);
        setIsLoading(false);
      })
      .catch((err) => {
        setShowModal(false);
        openMessageModal(
          "error",
          `${
            err?.response?.data?.error
              ? err?.response?.data?.error
              : "An error has occurred, please try again!"
          }`
        );
        setIsLoading(false);
      });
  };
  return (
    <div className="Profile">
      <p className="Profile__header">Profile</p>
      <div className="Profile__container">
        <div className="Profile__sidebar">
          <div
            className={cs(
              "Profile__sidebar-item",
              state.item === "user" &&
                state.active &&
                "Profile__sidebar-item--active"
            )}
            onClick={() =>
              setState({
                item: "user",
                active: true,
              })
            }
          >
            <div
              className={cs(
                "Profile__icon-wrapper",
                state.item === "user" &&
                  state.active &&
                  "Profile__icon-wrapper--active"
              )}
            >
              <Icon onClick={() => {}} className="Profile__icon" icon="user" />
            </div>
            <div>
              <h3 className="Profile__sidebar-item-title">
                Personal Informations
              </h3>
              <span className="Profile__sidebar-item-subtitle">
                Update your information.
              </span>
            </div>
          </div>
          <div
            className={cs(
              "Profile__sidebar-item",
              state.item === "lock" &&
                state.active &&
                "Profile__sidebar-item--active"
            )}
            onClick={() =>
              setState({
                item: "lock",
                active: true,
              })
            }
          >
            <div
              className={cs(
                "Profile__icon-wrapper",
                state.item === "lock" &&
                  state.active &&
                  "Profile__icon-wrapper--active"
              )}
            >
              {" "}
              <Icon onClick={() => {}} className="Profile__icon" icon="lock" />
            </div>
            <div>
              <h3 className="Profile__sidebar-item-title">Security</h3>
              <span className="Profile__sidebar-item-subtitle">
                Update your security information
              </span>
            </div>
          </div>
        </div>
        {state.item === "user" && state.active && (
          <div className="Profile__card">
            <h2 className="Profile__card-title">Personal Informations</h2>
            <>
              {showModal && (
                <MessageModal
                  onCancel={() => setShowModal(false)}
                  onAccept={() => {
                    if (authCtx?.userData?.picture_url) {
                      handleImageDelete(authCtx?.userData?.picture_url);
                    }
                  }}
                  toggle={() => setShowModal(!showModal)}
                  typeOfModal="danger"
                  isOpen={showModal}
                  title="Are you sure you want to permanently delete this image?"
                  description=""
                />
              )}
              <PopUp showPopUp={showPopUp} togglePopUp={setShowPopUp}>
                <FileUpload
                  errorMessage={fileUploadError}
                  acceptedFileType={{ "image/png": [".png", ".jpg", ".jpeg"] }}
                  onSubmit={(files) => {
                    if (files) {
                      handleUploadImage(files);
                    }
                  }}
                  onCancelClick={() => {
                    setShowPopUp(false);
                  }}
                  subtitle="Please upload an profile image"
                  title="Image Upload"
                  isLoading={isLoading && showPopUp}
                />
              </PopUp>
              <div className="Profile__image-wrapper">
                <div
                  className="Profile__upload"
                  onClick={() => setShowPopUp(true)}
                >
                  <Icon
                    onClick={() => {}}
                    icon="camera"
                    className="Profile__upload-camera"
                  />
                </div>
                {authCtx.userData?.picture_url ? (
                  <img
                    className="Profile__image"
                    src={authCtx.userData?.picture_url}
                    alt=""
                  />
                ) : (
                  <div
                    className="Profile__placeholder"
                    onClick={() => setShowPopUp(true)}
                  >
                    <span className="Profile__placeholder-name">
                      {authCtx.userData?.display_name
                        .split(" ")
                        .map((word: string) => word.charAt(0))
                        .join("")
                        .slice(0, 2)}
                    </span>
                  </div>
                )}
                <div>
                  <span className="Profile__description">
                    We only support .JPG, .JPEG, or .PNG file.
                  </span>
                  <div className="Profile__buttons-wrapper">
                    <Button
                      onClick={() => setShowPopUp(true)}
                      label="Upload your photo"
                      className="Profile__button"
                    />
                    <div
                      className={cs(
                        `Profile__delete-img`,
                        !authCtx?.userData?.picture_url &&
                          `Profile__delete-img--disabled`
                      )}
                      onClick={() => setShowModal(true)}
                    >
                      Delete Image
                    </div>
                  </div>
                </div>
              </div>
            </>
            <PersonalInfoForm />
          </div>
        )}
        {state.item === "lock" && state.active && (
          <div className="Profile__card">
            <h2 className="Profile__card-title">Security</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
