import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  /*  <React.StrictMode> */
  <Router>
    <App />
  </Router>
  /*   </React.StrictMode> */
);
