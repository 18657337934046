import React, { useEffect, useState } from "react";
import { AuthContext, AuthContextValuesType } from "./AuthContext";
import * as API from "../../../api/Api";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [userError, setUserError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("access_token") ? true : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [message, setMessage] = useState<any>({
    type: "info",
    title: "",
    subtitle: "",
  });
  const [userData, setUserData] = useState<any>(
    JSON.parse(localStorage.getItem("user")!!)
      ? JSON.parse(localStorage.getItem("user")!!)
      : {}
  );
  const [organizations, setOrganizations] = useState<any>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>(
    JSON.parse(localStorage.getItem("selectedOrganization")!!)
  );
  const [openPopUp, setOpenPopUp] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const getAllOrganizations = () => {
    API.getOrganizations()
      .then((res) => {
        setOrganizations(res?.data);
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(
    () => getAllOrganizations(),
    [selectedOrganization?.id, selectedOrganization?.organization_logo_url]
  );

  //checking if refresh token has expired, if true, logout user
  useEffect(() => {
    const refreshToken: any = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");

    if (accessToken !== null) {
      const tokenInfo: any = jwt_decode(refreshToken);
      const isExpired = dayjs.unix(tokenInfo.exp).diff(dayjs()) < 1;
      if (isExpired) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }, [location]);

  //handle reset password
  const handleResetPassword = (email: string) => {
    setIsLoading2(true);
    API.passwordReset(email)
      .then((res) => {
        setIsLoading2(false);
        if (res.data?.status === "OK") {
          setMessage({
            type: "success",
            title: "Success!",
            subtitle: "We have sent a link to your email.",
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.email) {
          setMessage({
            type: "error",
            title: "Error",
            subtitle: err?.response?.data?.email[0],
          });
        }
        setIsLoading2(false);
      });
  };

  // handle confirm reset password
  const handleConfirmResetPassword = (token: string, password: string) => {
    setIsLoading(true);
    API.confirmPasswordReset(token, password)
      .then((res) => {
        setIsLoading(false);
        if (res.data?.status === "OK") {
          setMessage({
            type: "success",
            title: "Success!",
            subtitle: "You have reset your password",
          });
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          setMessage({
            type: "error",
            title: "Error",
            subtitle: err?.response?.data?.detail,
          });
        }
        setIsLoading(false);
      });
  };

  //getting the user info
  const getUserData = () => {
    API.getUserProfile()
      .then((res) => {
        setUserData(res.data);
        localStorage.setItem("user", JSON.stringify(res.data));
      })
      .catch((err) => console.log(err, "err"));
  };

  //login
  const handleLogin = async (data: any) => {
    setIsLoading(true);
    API.login(data)
      .then((res) => {
        if (res.data.access) {
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
          localStorage.setItem("localStorageAuth", "true");
          setIsLoading(false);
          setIsAuthenticated(true);
          setUserError(false);
          getUserData();
          API.getOrganizations().then((res) => {
            setOrganizations(res?.data);
            if (res.data.length !== 0) {
              navigate("/organizations");
            } else {
              navigate("/create-organization");
            }
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail === "Email not verified") {
          setOpenPopUp(true);
          setIsLoading(false);
          return;
        }
        if (err?.response?.data?.detail) {
          setMessage({
            type: "error",
            title: err?.response?.data?.detail,
          });
        }
        setIsLoading(false);
      });
  };

  //logout
  const logOut = () => {
    localStorage.clear();
    setUserData({});
    setIsAuthenticated(false);
    setMessage({
      type: "info",
      title: "",
      subtitle: "",
    });
  };

  const context: AuthContextValuesType = {
    userError,
    isAuthenticated,
    handleLogin,
    logOut,
    isLoading,
    message,
    userData,
    getUserData,
    handleResetPassword,
    isLoading2,
    handleConfirmResetPassword,
    organizations,
    setSelectedOrganization,
    selectedOrganization,
    getAllOrganizations,
    setOpenPopUp,
    openPopUp,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
