import { createContext, useContext } from "react";

export interface AlertModalContextType {
  type: string;
  message: string;
  openMessageModal: (type: string, message: string) => void;
  closeMessageModal: () => void;
}

const AlertContextValues: AlertModalContextType = {
  type: "",
  message: "",
  openMessageModal: (type, message) => {},
  closeMessageModal: () => {},
};

export const AlertModalContext =
  createContext<AlertModalContextType>(AlertContextValues);
export const useAlertModalContext = () => useContext(AlertModalContext);
