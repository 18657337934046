import { useFormik } from "formik";
import * as Yup from "yup";

const NewWebhookSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Webhook name is too short!")
    .max(40, "Webhook name is too long!")
    .required("Enter webhook name"),
  description: Yup.string().required("Enter a webhook description"),
  url: Yup.string().required("Enter webhook url"),
  secret_key: Yup.string().required("Enter webhook secret key"),
});

export const useNewWebhookFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      url: "",
      secret_key: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: NewWebhookSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
