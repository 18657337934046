import React from "react";
import cs from "classnames";
import { Icon } from "../Icon/Icon";
import { IconDefinition } from "../Icon/Icon.generated";

import "./RadioButton.scss";

interface RadioButtonProps {
  label?: string;
  checked?: boolean;
  onCheck: (value: boolean) => void;
  id?: string;
  disabled?: boolean;
  disabledChecked?: boolean;
}

const RadioButton = (props: RadioButtonProps) => {
  const { label, checked, disabled, disabledChecked } = props;

  const onCheck = () => {
    props.onCheck(!checked);
  };

  return (
    <div className="RadioButton" onClick={onCheck}>
      <div
        className={cs(
          "RadioButton__circle",
          checked && `RadioButton__circle--checked`,
          disabled && `RadioButton__circle--disabled`,
          disabledChecked && `RadioButton__circle--disabled-checked`
        )}
      >
        <Icon onClick={() => {}} className="RadioButton__icon" icon="check" />
      </div>
      <div
        className={cs(
          "RadioButton__label",
          checked && `RadioButton__label--checked`,
          disabled && `RadioButton__label--disabled`,
          disabledChecked && `RadioButton__label--disabled-checked`
        )}
      >
        {label}
      </div>
    </div>
  );
};

export default RadioButton;
