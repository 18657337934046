import React, { useState } from "react";

import { useCreateOrganizationFormik } from "./useCreateOrganizationFormik";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import DropDownWithImg from "../../../components/shared/DropDownWithImg/DropDownWithImg";
import SelectBox from "../../../components/shared/SelectBox/SelectBox";
import { countries } from "country-list-json";
import * as API from "../../../api/Api";
import PopUp from "../../../components/shared/PopUp/PopUp";
import FileUpload from "../../../components/shared/FileUpload/FileUpload";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import "./CreateOrganization.scss";
import { BILLING_TYPES, billingTypesSelectInput } from "../../../constants/organization";

const CreateOrganization = () => {
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    flag: "",
  });
  const [showPopUp, setShowPopUp] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState<any>();
  const [selectedBillingType, setSelectedBillingType] = useState({
    name: billingTypesSelectInput[0].name,
    value: billingTypesSelectInput[0].value,
  });
  let accessToken = localStorage.getItem("access_token")!!;

  const authCtx = useAuthContext();
  const navigate = useNavigate();

  const formik: any = useCreateOrganizationFormik({
    onSubmit: (values: any) => {
      setIsLoading(true);
      const data = {
        name: values.name,
        category: values.category,
        address: values.address,
        billing_type: selectedBillingType?.value,
        country: selectedCategory?.name,
        city: values.city,
      };
      API.createOrganization(data)
        .then((res) => {
          if (res.status === 200 && !imageFiles) {
            navigate("/organizations");
          }
          if (res.status === 200 && imageFiles[0] !== undefined) {
            let formData = new FormData();
            formData.append("organization_logo", imageFiles[0]);
            let organizationId = res.data?.id;
            Axios.put(
              `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/upload_logo/`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: accessToken ? `Bearer ${accessToken}` : null,
                },
              }
            ).then((res) => {
              API.updateOrganization(organizationId, {
                organization_logo_url: res.data.organization_logo_url,
              })
                .then((res) => {
                  setTimeout(() => {
                    authCtx.getAllOrganizations();
                    navigate("/organizations");
                    setIsLoading(false);
                  }, 3000);
                })
                .catch((error) => {
                  console.log(error, "error!!!");
                });
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err, "err");
        });
    },
  });

  return (
    <>
      <PopUp showPopUp={showPopUp} togglePopUp={setShowPopUp}>
        <FileUpload
          errorMessage={fileUploadError}
          acceptedFileType={{ "image/png": [".png", ".jpg", ".jpeg"] }}
          onSubmit={(files) => {
            setImageFiles(files);
            setShowPopUp(false);
          }}
          onCancelClick={() => {
            setShowPopUp(false);
          }}
          subtitle="Please upload a profile picture"
          title="File Upload"
          isLoading={isLoading}
        />
      </PopUp>
      <div className="CreateOrganization">
        <div className="CreateOrganization__header">
          <h1 className="CreateOrganization__title">
            Create your organization
          </h1>
          <p className="CreateOrganization__subtitle">
            Lorem ipsum is placeholder text commonly used in the graphic and
            print.
          </p>
        </div>
        <form
          className="CreateOrganization__form"
          onSubmit={formik.handleSubmit}
        >
          <div className="CreateOrganization__input-container-row">
            <div className="CreateOrganization__input-group-container">
              <div className="CreateOrganization__input-container">
                <div className="CreateOrganization__label">
                  Organization name
                </div>
                <InputField
                  className="CreateOrganization__input"
                  onIconClick={() => {}}
                  name="name"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="CreateOrganization__invalid-feedback">
                    {formik.errors.name}
                  </div>
                )}
              </div>
            </div>
            <div className="CreateOrganization__input-group-container">
              <div className="CreateOrganization__input-container">
                <div className="CreateOrganization__label">
                  Organization category
                </div>
                <InputField
                  className="CreateOrganization__input"
                  onIconClick={() => {}}
                  name="category"
                  value={formik.values.category}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.category && formik.touched.category && (
                  <div className="CreateOrganization__invalid-feedback">
                    {formik.errors.category}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="CreateOrganization__label">Organization Image</div>
          <div className="CreateOrganization__image-container">
            {imageFiles && imageFiles?.[0]?.preview ? (
              <img
                alt=""
                src={imageFiles[0]?.preview}
                className="CreateOrganization__image-placeholder"
              />
            ) : (
              <div className="CreateOrganization__image-placeholder2" />
            )}

            <div className="CreateOrganization__image-info-wrapper">
              <div className="CreateOrganization__image-info">
                We only support .JPG, .JPEG, or .PNG file.
              </div>
              <Button
                label="Upload image"
                onClick={() => setShowPopUp(true)}
                className="Button CreateOrganization__upload-btn"
                type="button"
              />
            </div>
          </div>
          <div className="CreateOrganization__input-group-container">
            <div className="CreateOrganization__input-container">
              <div className="CreateOrganization__label">
                Billing Method
              </div>
              <SelectBox
                onItemSelect={(name, value) => setSelectedBillingType({ name: name, value: value })}
                onClick={() => {}}
                items={billingTypesSelectInput}
                selectedItem={selectedBillingType}
              />
              {formik.errors.billing_type && formik.touched.billing_type && (
                <div className="CreateOrganization__invalid-feedback">
                  {formik.errors.billing_type}
                </div>
              )}
            </div>
            <div className="CreateOrganization__input-container">
              <div className="CreateOrganization__label">Country</div>
              <DropDownWithImg
                items={countries}
                onItemSelect={(item: any) => {
                  setSelectedCategory({ name: item?.name, flag: item?.flag });
                }}
                selectedItem={selectedCategory}
              />
            </div>
          </div>
          <div className="CreateOrganization__input-group-container">
            <div className="CreateOrganization__input-container">
              <div className="CreateOrganization__label">City</div>
              <InputField
                className="CreateOrganization__input"
                onIconClick={() => {}}
                name="city"
                value={formik.values.city}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.city && formik.touched.city && (
                <div className="CreateOrganization__invalid-feedback">
                  {formik.errors.city}
                </div>
              )}
            </div>
          </div>
          <div className="CreateOrganization__input-group-container">
            <div className="CreateOrganization__input-container">
              <div className="CreateOrganization__label">Address</div>
              <InputField
                className="CreateOrganization__input"
                onIconClick={() => {}}
                name="address"
                value={formik.values.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.address && formik.touched.address && (
                <div className="CreateOrganization__invalid-feedback">
                  {formik.errors.address}
                </div>
              )}
            </div>
          </div>
          <Button
            label="Continue"
            onClick={() => {}}
            className="Button__primary CreateOrganization__submit-btn"
            type="submit"
            isLoading={isLoading}
            disabled={
              formik.values.name === "" ||
              formik.values.category === "" ||
              formik.values.city === "" ||
              formik.values.address === "" ||
              selectedCategory.name.length === 0
            }
          />
        </form>
      </div>
    </>
  );
};

export default CreateOrganization;
