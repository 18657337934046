import React, { useEffect, useState } from "react";
import { PaginationContext, PaginationContextTypes } from "./PaginationContext";

interface PaginationContextProviderProps {
  children: React.ReactNode | null;
}

export const PaginationContextProvider = (
  props: PaginationContextProviderProps
) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [offsetNumber, setOffsetNumber] = useState<number>(0);
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(5);
  const [selectedShowPage, setSelectedShowPage] = useState({
    name: "10",
  });

  const onItemSelect = (name: string) => {
    setSelectedShowPage({ name: name });
  };

  const selectedNumber = parseInt(selectedShowPage.name);

  const pagesArray: number[] = [];

  for (let i = 1; i <= 100; i++) {
    pagesArray.push(i);
  }

  const pagesArraySliced = pagesArray.slice(startPoint, endPoint);

  useEffect(() => {
    const offset = selectedPage * selectedNumber - selectedNumber;
    setOffsetNumber(offset);
  });

  const firstPage = () => {
    setStartPoint(0);
    setEndPoint(5);
    setSelectedPage(1);
  };

  const endPage = () => {
    setStartPoint(pagesArray.length - 5);
    setEndPoint(pagesArray.length);
    setSelectedPage(pagesArray.length);
  };

  const prevPage = () => {
    if (selectedPage > 1) {
      let changeSelectedPage = selectedPage - 1;
      setSelectedPage(changeSelectedPage);
    }

    if (startPoint >= 1 && selectedPage <= pagesArray.length - 2) {
      let incrementStartPoint = startPoint - 1;
      let incrementEndpoint = endPoint - 1;
      setStartPoint(incrementStartPoint);
      setEndPoint(incrementEndpoint);
    }
  };

  const nextPage = () => {
    if (selectedPage < pagesArray.length) {
      let changeSelectedPage = selectedPage + 1;
      setSelectedPage(changeSelectedPage);
    }

    if (endPoint < pagesArray.length && selectedPage >= 3) {
      let incrementStartPoint = startPoint + 1;
      let incrementEndpoint = endPoint + 1;
      setStartPoint(incrementStartPoint);
      setEndPoint(incrementEndpoint);
    }
  };

  const onPageSelect = (item: any) => {
    setSelectedPage(item);

    let changeStartPoint = 0;
    let changeEndPoint = 5;

    if (item > 2 && item <= pagesArray.length - 2) {
      changeStartPoint = item - 3;
      changeEndPoint = item + 2;
    }
    if (item === 2) {
      changeStartPoint = item - 2;
      changeEndPoint = item + 3;
    }
    if (item === pagesArray.length - 1) {
      changeStartPoint = item - 4;
      changeEndPoint = item + 1;
    }
    if (item === pagesArray.length) {
      changeStartPoint = item - 5;
      changeEndPoint = item;
    }

    setStartPoint(changeStartPoint);
    setEndPoint(changeEndPoint);
  };

  const context: PaginationContextTypes = {
    selectedPage,
    offsetNumber,
    pagesArraySliced,
    selectedShowPage,
    selectedNumber,
    firstPage,
    endPage,
    prevPage,
    nextPage,
    onPageSelect,
    onItemSelect,
    setSelectedPage,
  };

  return (
    <PaginationContext.Provider value={context}>
      {props.children}
    </PaginationContext.Provider>
  );
};
