import React from "react";

import BouncerContainer from "../../components/shared/BouncerContainer/BouncerContainer";
import { Link } from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import bouncerLogo2 from "../../assets/images/bouncer-logo2.svg";
// import Button from "../../components/shared/Button/Button";
// import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./Login.scss";
import AuthAlert from "../../components/shared/AuthAlert/AuthAlert";

const Login = () => {
  const isTablet = useResponsiveDimensions().isTablet;
  // const navigate = useNavigate();

  const authCtx = useAuthContext();

  return (
    <div className="Login">
      {!isTablet && <BouncerContainer />}
      <div className="Login__container">
        {isTablet && (
          <div className="Login__logo-wrapper">
            <Link to="/" className="Login__logo-link">
              <img
                src={bouncerLogo2}
                alt=""
                height="56"
                className="Login__logo"
              />
            </Link>
          </div>
        )}
        {authCtx.message?.title && (
          <AuthAlert
            title={authCtx.message?.title}
            type={authCtx.message?.type}
            subtitle={authCtx.message?.subtitle}
          />
        )}

        <div className="Login__wrapper">
          <h1 className="Login__title">Log in to your account</h1>
          {isTablet && (
            <p className="Login__subtitle">Enter your account information.</p>
          )}
          <LoginForm />
        </div>
        {!isTablet && (
          <div className="Login__footer">
            {/* Don't have an account?
            <Link to="/sign-up" className="Login__footer-link">
              Get Started
            </Link> */}
          </div>
        )}

        {isTablet && (
          <div className="Login__mobile-footer">
            <div className="Login__separator">
              <span>OR</span>
            </div>
            <div>
              {/* <Button
                label="Create an account"
                onClick={() => navigate("/sign-up")}
                className="Button__primary--outline Login__footer-btn"
                type="button"
              /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
