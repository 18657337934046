import React, { useEffect, useState } from "react";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import InputField from "../../../components/shared/InputField/InputField";
import * as API from "../../../api/Api";
import { useAlertModalContext } from "../../../lib/context/AlertModalContext/AlertModalContext";
import { checkIfObjectHasChanged } from "../../../lib/helpers/checkIfObjectHasChanged";

import { useEditWebhookFormik } from "./useEditWebhook";
import SwitchButton from "../../../components/shared/SwitchButton/SwitchButton";

import "./EditWebhook.scss";

interface editWebhookFormProp {
  onClose: () => void;
  refresh: () => void;
  singleWebhook: any;
  clearWebhook: () => void;
  organizationId: any;
}

const EditWebhook = (props: editWebhookFormProp) => {
  const { onClose, refresh, singleWebhook, clearWebhook, organizationId } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(singleWebhook?.active);
  const [formChanged, setFormChanged] = useState(false);
  const [devicesNames, setDevicesName] = useState<any>([]);

  const openMessageModal = useAlertModalContext().openMessageModal;

  useEffect(() => {
    if (singleWebhook) {
      singleWebhook?.target.map((item: any) => {
        API.getDeviceFromOrganization(organizationId, item)
          .then((res) => {
            setDevicesName((prev: any) => [...prev, res.data.name]);
          })
          .catch((err) => console.log(err));
      });
    }
  }, []);

  const formik: any = useEditWebhookFormik(singleWebhook, {
    onSubmit: async (values: any) => {
      setIsLoading(true);
      const data = {
        name: values.name,
        description: values.description,
        active: isActive,
      };

      API.updateWebhook(organizationId, singleWebhook?.id, data)
        .then((res) => {
          setIsLoading(false);
          refresh();
          onClose();
          clearWebhook();
        })
        .catch((err) => {
          console.error(err);
          onClose();
          openMessageModal(
            "error",
            `${
              err?.response?.data?.error
                ? err?.response?.data?.error
                : "An error has occurred, please try again!"
            }`
          );
          setIsLoading(false);
        });
    },
  });

  //has form changed?
  useEffect(() => {
    if (singleWebhook?.id) {
      const formValues = {
        name: formik.values.name,
        description: formik.values.description,
        active: isActive,
      };
      const userValues = {
        name: singleWebhook?.name,
        description: singleWebhook?.description,
        active: singleWebhook?.active,
      };
      const isChanged = checkIfObjectHasChanged(userValues, formValues);
      if (isChanged) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    }
  }, [
    singleWebhook?.name,
    singleWebhook?.description,
    singleWebhook?.active,
    isActive,
    formik.values,
  ]);

  return (
    <form className="EditWebhook" onSubmit={formik.handleSubmit}>
      <h3 className="EditWebhook__title">Edit Webhook</h3>

      <div className="EditWebhook__main-container">
        <div className="EditWebhook__input-group-container">
          <div className="EditWebhook__input-label">Name</div>
          <InputField
            type="text"
            className="EditWebhook__form-control"
            name="name"
            value={formik.values.name}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.name && formik.touched.device_name && (
            <div className="EditWebhook__invalid-feedback">
              {formik.errors.name}
            </div>
          )}
        </div>
        <div className="EditWebhook__input-group-container">
          <div className="EditWebhook__input-label">Description</div>
          <InputField
            type="text"
            className="EditWebhook__form-control"
            name="description"
            value={formik.values.description}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.description && formik.touched.description && (
            <div className="EditWebhook__invalid-feedback">
              {formik.errors.description}
            </div>
          )}
        </div>
        <div className="EditWebhook__input-group-container2">
          <div className="EditWebhook__input-label2">Active</div>
          <SwitchButton
            onSwitch={() => setIsActive(!isActive)}
            switchState={isActive}
          />
        </div>

        <div className="EditWebhook__input-group-container2">
          <div className="EditWebhook__input-label2">Method</div>
          <div className="EditWebhook__input-value">
            {singleWebhook?.method}
          </div>
        </div>
        <div className="EditWebhook__input-group-container">
          <div className="EditWebhook__input-label">URL</div>
          <InputField
            type="text"
            className="EditWebhook__form-control"
            name="url"
            value={singleWebhook?.url}
            onBlur={() => {}}
            onChange={() => {}}
            onIconClick={() => {}}
            disabled
          />
        </div>
        <div className="EditWebhook__input-group-container">
          <div className="EditWebhook__input-label">Secret Key</div>
          <InputField
            type="text"
            className="EditWebhook__form-control"
            name="secret_key"
            value={singleWebhook?.secret_key}
            onBlur={() => {}}
            onChange={() => {}}
            onIconClick={() => {}}
            disabled
          />
        </div>
        <div className="EditWebhook__input-group-container">
          <div className="EditWebhook__input-label">Devices</div>
          <div className="EditWebhook__input-devices-wrapper">
            {devicesNames &&
              devicesNames.map((item: any, index: number) => (
                <div key={index} className="EditWebhook__input-devices">
                  {(index ? ", " : "") + item}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="EditWebhook__buttons">
        <Button
          className={cs("Button__primary--outline", `EditWebhook__discard-btn`)}
          label="Discard"
          onClick={() => {
            onClose();
            clearWebhook();
          }}
          type="button"
        />
        <Button
          isLoading={isLoading}
          className={cs("Button", "EditWebhook__save-btn")}
          label="Save"
          onClick={() => {}}
          type="submit"
          disabled={
            formik.errors.name !== undefined ||
            formik.errors.description !== undefined ||
            !formChanged ||
            isLoading
          }
        />
      </div>
    </form>
  );
};

export default EditWebhook;
