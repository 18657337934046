import { apiRequest } from "./Api";

export const getAllDevicesFromOrganization = async (organizationId: string) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/devices/?limit=50&offset=0`
  );
};

export const getDeviceFromOrganization = async (
  organizationId: string,
  deviceId: string
) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/devices/${deviceId}/`
  );
};

export const editDeviceFromOrganization = async (
  organizationId: string,
  deviceId: string,
  data: any
) => {
  return apiRequest<any, any>(
    "put",
    `organizations/${organizationId}/devices/${deviceId}/`,
    data
  );
};

export const createDeviceFromOrganization = async (
  organizationId: string,
  data: any
) => {
  return apiRequest<any, any>(
    "post",
    `organizations/${organizationId}/devices/`,
    data
  );
};

export const deleteDeviceFromOrganization = async (
  organizationId: string,
  deviceId: string
) => {
  return apiRequest<any, any>(
    "delete",
    `organizations/${organizationId}/devices/${deviceId}/`
  );
};

export const getDeviceQrCode = async (
  organizationId: string,
  deviceId: string
) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/devices/${deviceId}/qr_code/`
  );
};

export const simulateScanForDevice = async (
  organizationId: string,
  deviceId: string
) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/devices/${deviceId}/simulate_scan/`
  );
};

//Get device verification count
export const getDeviceVerificationCount = async (
  organizationId: string,
  deviceId: string,
  path: string
) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/devices/${deviceId}/analytics/?${path}`
  );
};
