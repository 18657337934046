import { apiRequest } from "./Api";

export interface WebhooksInput {
  organizationId: string;
}

export const getAllWebhooks = async (organizationId: WebhooksInput) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/webhooks/?limit=50&offset=0`
  );
};

export const createWebhook = async (organizationId: string, data: any) => {
  return apiRequest<any, any>(
    "post",
    `organizations/${organizationId}/webhooks/`,
    data
  );
};

export const getWebhookById = async (
  organizationId: string,
  webhookId: string
) => {
  return apiRequest<any, any>(
    "get",
    `organizations/${organizationId}/webhooks/${webhookId}/`
  );
};

export const deleteWebhookById = async (
  organizationId: string,
  webhookId: string
) => {
  return apiRequest<any, any>(
    "delete",
    `organizations/${organizationId}/webhooks/${webhookId}/`
  );
};

export const updateWebhook = async (
  organizationId: string,
  webhookId: string,
  data: any
) => {
  return apiRequest<any, any>(
    "put",
    `organizations/${organizationId}/webhooks/${webhookId}/`,
    data
  );
};

export const getWebhooksLogs = async (path: string) => {
  return apiRequest<any, any>("get", path);
};
