import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button/Button";
import * as API from "../../api/Api";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useAlertModalContext } from "../../lib/context/AlertModalContext/AlertModalContext";
import Item from "./Item/Item";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import SecureKeyLoader from "../../components/shared/SkeletonLoader/SecureKeyLoader";

import "./SecureKey.scss";

const SecureKey = () => {
  const [secureKey, setSecureKey] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useAuthContext();
  const openMessageModal = useAlertModalContext().openMessageModal;
  const isMobile = useResponsiveDimensions().isMobile;
  const isTablet = useResponsiveDimensions().isTablet;
  const isBigTablet = useResponsiveDimensions().isBigTablet;

  const selectedOrganization = JSON.parse(
    localStorage.getItem("selectedOrganization")!!
  );

  useEffect(() => {
    setIsLoading(true);
    API.getSecureKey(selectedOrganization?.id)
      .then((res) => {
        if (res?.data) {
          setSecureKey(res.data[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  const secureKeyRoll = () => {
    setSecureKey([]);
    setIsLoading(true);
    API.getSecureKeyRoll(selectedOrganization?.id)
      .then((res) => {
        if (res.data) {
          setSecureKey(res.data);
          openMessageModal("success", ` Success!`);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        openMessageModal(
          "error",
          `${
            err?.response?.data?.error
              ? err?.response?.data?.error
              : "An error has occurred, please try again!"
          }`
        );
      });
  };

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 3000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  return (
    <div className="SecureKey">
      <div className="SecureKey__header">
        <p className="SecureKey__header-title"> Secure Key </p>
        <Button
          onClick={() => secureKeyRoll()}
          label="Roll key"
          className="SecureKey__header-button"
          icon="roll-icon"
        />
      </div>
      <div className="SecureKey__container">
        {isLoading ? (
          <SecureKeyLoader
            style={{
              width: isMobile || isTablet || isBigTablet ? "100%" : 1000,
            }}
          />
        ) : (
          <>
            {secureKey && !isLoading ? (
              <div className="Webhooks__grid">
                <Item
                  item={secureKey}
                  handleCopy={copyToClipBoard}
                  copySuccess={copySuccess}
                />
              </div>
            ) : (
              <div className="SecureKey__no-content">
                {" "}
                No secure key found!{" "}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SecureKey;
