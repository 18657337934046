import React, { useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";

import "./HeaderProfile.scss";
import { Icon } from "../../shared/Icon/Icon";
import SwitchButton from "../../shared/SwitchButton/SwitchButton";

const HeaderProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSwiched, setIsSwiched] = useState(false);
  const headerProfileRef = useRef(null);

  const isMobile = useResponsiveDimensions().isMobile;
  const authCtx = useAuthContext();
  const navigate = useNavigate();

  const handleOutSideClick = () => {
    setIsOpen(false);
  };

  useOutsideClickDetection(headerProfileRef, handleOutSideClick);

  return (
    <div className="HeaderProfile" ref={headerProfileRef}>
      <div
        className="HeaderProfile__head"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {authCtx.userData?.picture_url ? (
          <img
            className="HeaderProfile__picture"
            src={authCtx.userData?.picture_url}
            alt=""
          />
        ) : (
          <div className="HeaderProfile__placeholder">
            {" "}
            {authCtx.userData?.display_name
              .split(" ")
              .map((word: string) => word.charAt(0))
              .join("")
              .slice(0, 2)}
          </div>
        )}

        <div className="HeaderProfile__info">
          {!isMobile && (
            <>
              <p className="HeaderProfile__name">
                {authCtx.userData?.display_name}
              </p>
              <p className="HeaderProfile__role">Administrator</p>
            </>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="HeaderProfile__body">
          <div
            className="HeaderProfile__profile"
            onClick={() => {
              setIsOpen(false);
              navigate("/profile");
            }}
          >
            <Icon
              onClick={() => {}}
              className="HeaderProfile__icon"
              icon="profile-circle"
            />
            <span>My Profile</span>
          </div>
          <div className="HeaderProfile__profile">
            <Icon
              onClick={() => {}}
              className="HeaderProfile__icon"
              icon="moon"
            />
            <span> Dark Mode</span>
            <SwitchButton
              onSwitch={() => setIsSwiched(!isSwiched)}
              switchState={isSwiched}
              className="HeaderProfile__switch"
            />
          </div>
          <div
            className="HeaderProfile__logout"
            onClick={() => authCtx.logOut()}
          >
            <Icon
              onClick={() => {}}
              className="HeaderProfile__icon"
              icon="logout"
            />
            <span>Log Out</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderProfile;
