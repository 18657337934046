import React from "react";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { useSidebarContext } from "../lib/context/SidebarContext/SidebarContext";
import { useResponsiveDimensions } from "../lib/hooks/useResponsiveDimensions";
import cs from "classnames";
import AlertModal from "../components/shared/AlertModal/AlertModal";

import "./Layout.scss";

interface LayoutPropsType {
  children: React.ReactNode;
}

export const Layout = (props: LayoutPropsType) => {
  const { children } = props;
  const isSidebarOpen = useSidebarContext().isSidebarOpen;
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="Layout">
      <Sidebar />
      <div
        className={cs(
          "Layout__container",
          isSidebarOpen && "Layout__container--open",
          isMobile && "Layout__container--mobile"
        )}
      >
        <Header />
        <AlertModal />
        <div className="Layout__routes-container">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
