import React from "react";
import { Icon } from "../../../components/shared/Icon/Icon";

import "./Organization.scss";
import { BILLING_TYPES } from "../../../constants/organization";

interface OrganizationProps {
  id?: string;
  image?: string;
  name?: string;
  item?: any;
  pickOrganization?: any;
}

const Organization = (props: OrganizationProps) => {
  const { item } = props;
  const org_billing_type: number = item?.billing_type ?? 1;

  return (
    <div className="Organization" onClick={() => props.pickOrganization(item)}>
      {item?.organization_logo_url ? (
        <img
          src={item?.organization_logo_url}
          alt=""
          className="Organization__image"
        />
      ) : (
        <div className="Organization__placeholder" />
      )}
      <p className="Organization__name">{item?.name} - {BILLING_TYPES[org_billing_type]}</p>
      <div className="Organization__link-wrapper">
        <span>Go to project</span>
        <Icon
          onClick={() => {}}
          className="Organization__icon"
          icon="arrow-right"
        />{" "}
      </div>
    </div>
  );
};

export default Organization;
