import React, { useState, useEffect } from "react";

import { usePersonalInfoFormik } from "./usePersonalInfoFormik";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import InputField from "../../../components/shared/InputField/InputField";
import * as API from "../../../api/Api";
import { checkIfObjectHasChanged } from "../../../lib/helpers/checkIfObjectHasChanged";
import { useAlertModalContext } from "../../../lib/context/AlertModalContext/AlertModalContext";

import "./PersonalInfoForm.scss";

const PersonalInfoForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const authCtx = useAuthContext();
  const openMessageModal = useAlertModalContext().openMessageModal;

  const formik: any = usePersonalInfoFormik(authCtx.userData, {
    onSubmit: async (values: any) => {
      console.log(values.phone_number, "values.phone_number ");
      const data = {
        display_name: values.display_name,
        phone_number: values.phone_number ? values.phone_number.toString() : "",
      };
      setIsLoading(true);

      API.updateUserProfile(data)
        .then((res) => {
          if (res?.status === 200) {
            authCtx.getUserData();
            openMessageModal("success", `Success, profile has been updated!`);
            setIsLoading(false);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          openMessageModal(
            "error",
            `${
              err?.response?.data?.error
                ? err?.response?.data?.error
                : "An error has occurred, please try again!"
            }`
          );
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    if (authCtx.userData) {
      const formValues = {
        display_name: formik.values.display_name,
        phone_number: formik.values.phone_number.toString(),
      };
      const userValues = {
        display_name: authCtx.userData.display_name,
        phone_number: authCtx.userData.phone_number,
      };
      const isChanged = checkIfObjectHasChanged(userValues, formValues);
      if (isChanged) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    }
  }, [
    authCtx.userData,
    formik.values.display_name,
    formik.values.phone_number,
  ]);

  return (
    <form onSubmit={formik.handleSubmit} className="PersonalInfoForm__form">
      <div className="PersonalInfoForm__input-group-row">
        <div className="PersonalInfoForm__input-group-container">
          <div className="PersonalInfoForm__form-control-label">Full Name</div>
          <InputField
            type="text"
            className="PersonalInfoForm__form-control"
            name="display_name"
            value={formik.values.display_name}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.display_name && formik.touched.display_name && (
            <div className="PersonalInfoForm__invalid-feedback">
              {formik.errors.display_name}
            </div>
          )}
        </div>
        <div className="PersonalInfoForm__input-group-container">
          <div className="PersonalInfoForm__input-wrapper">
            <div className="PersonalInfoForm__form-control-label">
              Phone Number(optional)
            </div>
            <InputField
              type="text"
              className="PersonalInfoForm__form-control"
              name="phone_number"
              value={formik.values.phone_number}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
            />
            {formik.errors.phone_number && formik.touched.phone_number && (
              <div className="PersonalInfoForm__invalid-feedback">
                {formik.errors.phone_number}
              </div>
            )}
          </div>
        </div>
      </div>
      <button
        className="Button__primary PersonalInfoForm__btn"
        type="submit"
        disabled={
          formik.errors?.display_name ||
          formik.errors?.phone_number ||
          formik.values.display_name.length === 0 ||
          !formChanged ||
          isLoading
        }
      >
        {isLoading && !showPopUp ? (
          <div className="PersonalInfoForm__spinner" />
        ) : (
          <>Edit Profile</>
        )}
      </button>
    </form>
  );
};

export default PersonalInfoForm;
