import React from "react";
import ContentLoader from "react-content-loader";

const WebhooksLogLoader = (props) => (
  <ContentLoader
    speed={4}
    width={1600}
    height={500}
    viewBox="0 0 1600 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: "100%" }}
    {...props}
  >
    <rect x="120" y="110" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="420" y="110" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="750" y="110" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="1050" y="110" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="1300" y="110" rx="4" ry="4" width="100" height="25"></rect>

    <rect x="50" y="150" rx="4" ry="4" width="1400" height="2"></rect>

    <rect x="120" y="170" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="420" y="170" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="750" y="170" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="1050" y="170" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="1300" y="170" rx="4" ry="4" width="100" height="25"></rect>

    <rect x="50" y="215" rx="4" ry="4" width="1400" height="2"></rect>

    <rect x="120" y="235" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="420" y="235" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="750" y="235" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="1050" y="235" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="1300" y="235" rx="4" ry="4" width="100" height="25"></rect>

    <rect x="50" y="280" rx="4" ry="4" width="1400" height="2"></rect>

    <rect x="120" y="305" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="420" y="305" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="750" y="305" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="1050" y="305" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="1300" y="305" rx="4" ry="4" width="100" height="25"></rect>

    <rect x="50" y="350" rx="4" ry="4" width="1400" height="2"></rect>

    <rect x="120" y="370" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="420" y="370" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="750" y="370" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="1050" y="370" rx="4" ry="4" width="80" height="25"></rect>
    <rect x="1300" y="370" rx="4" ry="4" width="100" height="25"></rect>
  </ContentLoader>
);

export default WebhooksLogLoader;
