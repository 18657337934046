import React, { useState, useRef } from "react";
import { Icon } from "../../shared/Icon/Icon";
import cs from "classnames";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";

import "./HeaderOrganization.scss";

const HeaderOrganization = (props: any) => {
  const { items, selectedItem } = props;

  const [showItems, setShowItems] = useState(false);
  const [showUploadDiv, setShowUploadDiv] = useState(false);

  const dropDownRef = useRef(null);
  const authCtx = useAuthContext();

  const onItemSelect = (item: any) => {
    props.onItemSelect({ name: item?.name, image: item.organization_logo_url });
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <>
      <div className="HeaderOrganization" ref={dropDownRef}>
        <div
          className="HeaderOrganization__container"
          onClick={() => setShowItems(!showItems)}
        >
          <div className="HeaderOrganization__image-container">
            {selectedItem?.organization_logo_url ? (
              <img
                className="HeaderOrganization__image"
                src={selectedItem?.organization_logo_url}
                alt=""
              />
            ) : (
              <div className="HeaderOrganization__placeholder">
                <span className="HeaderOrganization__placeholder-name">
                  {selectedItem?.name
                    .split(" ")
                    .map((word: string) => word.charAt(0))
                    .join("")
                    .slice(0, 2)}
                </span>
              </div>
            )}
            <div
              className={cs(
                "HeaderOrganization__upload",
                showUploadDiv && "HeaderOrganization__upload--show"
              )}
              onClick={() => {
                props.setShowPopUp(true);
              }}
            >
              <Icon
                onClick={() => {}}
                icon="camera"
                className="HeaderOrganization__upload-camera"
              />
            </div>
          </div>
          <div className="HeaderOrganization__label-container">
            <div className="HeaderOrganization__name-container">
              <span className="HeaderOrganization__name">
                {selectedItem?.name}
              </span>
              <Icon
                onClick={() => {}}
                className="HeaderOrganization__icon"
                icon="chevron-down"
              />
            </div>
            <div className="HeaderOrganization__label">Your Organization</div>
          </div>
        </div>

        <div className={cs("HeaderOrganization__items", showItems && "active")}>
          {items.map((item: any, index: number) => {
            return (
              <div
                key={index}
                onClick={() => {
                  onItemSelect({
                    name: item.name,
                    image: item.organization_logo_url,
                  });
                  localStorage.setItem(
                    "selectedOrganization",
                    JSON.stringify(item)
                  );
                  authCtx.setSelectedOrganization(item);
                }}
                className={`HeaderOrganization__item-container ${
                  props.selectedItem?.name === item?.name ? "activeLabel" : ""
                }`}
              >
                <div className="HeaderOrganization__item">
                  {item?.organization_logo_url ? (
                    <img
                      className="HeaderOrganization__item-image"
                      src={item?.organization_logo_url}
                      alt=""
                    />
                  ) : (
                    <div className="HeaderOrganization__item-image-placeholder">
                      <span className="HeaderOrganization__item-image-placeholder-name">
                        {item?.name
                          .split(" ")
                          .map((word: string) => word.charAt(0))
                          .join("")
                          .slice(0, 2)}
                      </span>{" "}
                    </div>
                  )}

                  {item?.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HeaderOrganization;
