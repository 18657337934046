import React, { useState, useEffect } from "react";

import cs from "classnames";
import { useSidebarContext } from "../../lib/context/SidebarContext/SidebarContext";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import HeaderProfile from "./HeaderProfile/HeaderProfile";
import HamburgerButton from "./HamburgerButton/HumburgerButton";
import HeaderOrganization from "./HeaderOrganization/HeaderOrganization";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import FileUpload from "../shared/FileUpload/FileUpload";
import PopUp from "../shared/PopUp/PopUp";
import * as API from "../../api/Api";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

import "./Header.scss";

const Header = () => {
  const [showShadow, setShowShadow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    image: "",
  });
  const [showPopUp, setShowPopUp] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isSidebarOpen = useSidebarContext().isSidebarOpen;
  const isMobile = useResponsiveDimensions().isMobile;
  const authCtx = useAuthContext();
  let accessToken = localStorage.getItem("access_token")!!;
  let location = useLocation();

  window.addEventListener("scroll", () => {
    const lastKnownScrollPosition = window.scrollY;

    if (lastKnownScrollPosition > 40) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  });

  const selectedOrganization = JSON.parse(
    localStorage.getItem("selectedOrganization")!!
  );

  //checking if refresh token has expired, if true, logout user
  useEffect(() => {
    const refreshToken: any = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");

    if (accessToken !== null) {
      const tokenInfo: any = jwt_decode(refreshToken);
      const isExpired = dayjs.unix(tokenInfo.exp).diff(dayjs()) < 1;
      if (isExpired) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }, [location]);

  const handleUploadImage = (files: any) => {
    if (files[0] !== undefined) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("organization_logo", files[0]);

      Axios.put(
        `${process.env.REACT_APP_API_URL}/organizations/${selectedOrganization?.id}/upload_logo/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
        }
      )
        .then((res) => {
          if (authCtx.selectedOrganization) {
            authCtx.selectedOrganization.organization_logo_url =
              res.data.organization_logo_url;
          }
          API.updateOrganization(authCtx.selectedOrganization?.id, {
            organization_logo_url: res.data.organization_logo_url,
          })
            .then((res) => {
              localStorage.setItem(
                "selectedOrganization",
                JSON.stringify(res.data)
              );
              authCtx.getAllOrganizations();
              setIsLoading(false);
              setShowPopUp(false);
            })
            .catch((error) => {
              console.log(error, "error!!!");
              setIsLoading(false);
            });
        })
        .catch((error) => {
          setFileUploadError(error.message);
          setTimeout(() => {
            setFileUploadError("");
          }, 7000);
        });
    }
  };

  return (
    <>
      <PopUp showPopUp={showPopUp} togglePopUp={setShowPopUp}>
        <FileUpload
          errorMessage={fileUploadError}
          acceptedFileType={{ "image/png": [".png", ".jpg", ".jpeg"] }}
          onSubmit={(files) => {
            handleUploadImage(files);
          }}
          onCancelClick={() => {
            setShowPopUp(false);
          }}
          subtitle="Please upload an organization image"
          title="Image Upload"
          isLoading={isLoading}
        />
      </PopUp>
      <div
        className={cs(
          "Header",
          showShadow && "Header__shadow",
          isSidebarOpen && "Header__open",
          isMobile && "Header__mobile"
        )}
      >
        <div className="Header__left">
          {isMobile && <HamburgerButton />}
          {selectedOrganization?.name && (
            <HeaderOrganization
              items={authCtx.organizations}
              onItemSelect={(item: any) => {
                setSelectedCategory({
                  name: item?.name,
                  image: item?.organization_logo_url,
                });
              }}
              selectedItem={selectedOrganization}
              setShowPopUp={setShowPopUp}
            />
          )}
        </div>
        <div className="Header__right">
          <HeaderProfile />{" "}
        </div>
      </div>
    </>
  );
};

export default Header;
