import React, { useState } from "react";
import { Icon } from "../Icon/Icon";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import cs from "classnames";
import { usePaginationContext } from "../../../lib/context/PaginationContext/PaginationContext";

import "./Pagination.scss";

const Pagination = () => {
  const isMobile = useResponsiveDimensions().isMobile;
  const paginationCtx = usePaginationContext();
  const [isBodyOpen, setIsBodyOpen] = useState(false);

  const items = [
    {
      name: "5",
    },
    {
      name: "10",
    },
    {
      name: "20",
    },
    {
      name: "30",
    },
    {
      name: "40",
    },
  ];

  return (
    <div className="Pagination">
      <div className="Pagination__select">
        {!isMobile && (
          <div className="Pagination__description disable-selection">
            Show result: {paginationCtx.offsetNumber} to{" "}
            {paginationCtx.offsetNumber + paginationCtx.selectedNumber}
          </div>
        )}

        <div className="Pagination__dropdown">
          <div
            className="Pagination__dropdown-header"
            onClick={() => {
              setIsBodyOpen(true);
            }}
          >
            <div className="Pagination__dropdown-header-label">
              {paginationCtx.selectedShowPage.name}
            </div>
            <Icon
              icon="chevron-down"
              className="Pagination__dropdown-header-icon"
            />
          </div>
          {isBodyOpen && (
            <div className="Pagination__dropdown-body">
              {items.map((item) => (
                <div
                  className="Pagination__dropdown-body-item"
                  onClick={() => {
                    paginationCtx.onItemSelect(item.name);
                    setIsBodyOpen(false);
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="Pagination__content">
        <div className="Pagination__link" onClick={paginationCtx.prevPage}>
          <Icon
            onClick={() => {}}
            className="Pagination__icon"
            icon="chevron-left"
          />
        </div>

        {paginationCtx.pagesArraySliced.map((item: any, index) => (
          <div
            className={cs(
              "Pagination__link",
              "Pagination__link--page",
              item === paginationCtx.selectedPage &&
                "Pagination__link--page-active",
              "disable-selection"
            )}
            onClick={() => {
              paginationCtx.onPageSelect(item);
            }}
            key={index}
          >
            {item}
          </div>
        ))}

        <div
          className="Pagination__link Pagination__link--right-icon"
          onClick={paginationCtx.nextPage}
        >
          <Icon
            onClick={() => {}}
            className="Pagination__icon"
            icon="chevron-right"
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
