import { apiRequest } from "./Api";

export interface RegisterInput {
  display_name: string;
  email: string;
  password: string;
}

export interface LoginInput {
  email: string;
  password: string;
}

export const register = async (data: RegisterInput) => {
  return apiRequest<any, any>("post", "users/signup/", data);
};

export const verifyEmail = async (userId: string, token: string) => {
  return apiRequest<any, any>(
    "get",
    `users/verify-email/?user_id=${userId}&confirmation_token=${token}`
  );
};

export const resendEmailVerification = async (email: string) => {
  return apiRequest<any, any>("post", "users/api/resend-email-verification/", {
    user_email: email,
  });
};

export const login = async (data: LoginInput) => {
  return apiRequest<any, any>("post", "users/api/token/", data);
};

export const getUserProfile = async () => {
  return apiRequest<any, any>("get", "users/profile/");
};

export const putUserImage = async (data: any) => {
  return apiRequest<any, any>("put", "users/upload_profile_image/", data);
};

export const updateUserProfile = async (data: any) => {
  return apiRequest<any, any>("post", "users/profile/", data);
};

export const passwordReset = async (data: string) => {
  return apiRequest<any, any>("post", "users/api/password_reset/", {
    email: data,
  });
};

export const confirmPasswordReset = async (token: string, password: string) => {
  return apiRequest<any, any>("post", "users/api/password_reset/confirm/", {
    token: token,
    password: password,
  });
};
