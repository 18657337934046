import React from "react";
import Routes from "./routes/Routes";
import { SideBarContextProvider } from "./lib/context/SidebarContext/SidebarContextProvider";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { PaginationContextProvider } from "./lib/context/PaginationContext/PaginationContextProvider";
import { AlertModalContextProvider } from "./lib/context/AlertModalContext/AlertModalContextProvider";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <SideBarContextProvider>
        <AlertModalContextProvider>
          <AuthContextProvider>
            <PaginationContextProvider>
              <Routes />
            </PaginationContextProvider>
          </AuthContextProvider>
        </AlertModalContextProvider>
      </SideBarContextProvider>
    </div>
  );
}

export default App;
