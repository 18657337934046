import React from "react";
import ContentLoader from "react-content-loader";

const WebhookLoader = (props) => (
  <ContentLoader
    speed={4}
    width={1000}
    height={500}
    viewBox="0 0 1000 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="14" cy="140" r="12" />
    <rect x="50" y="110" rx="4" ry="4" width="280" height="25"></rect>
    <rect x="730" y="110" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="50" y="150" rx="4" ry="4" width="780" height="25"></rect>

    <circle cx="14" cy="250" r="12" />
    <rect x="50" y="220" rx="4" ry="4" width="280" height="25"></rect>
    <rect x="730" y="220" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="50" y="260" rx="4" ry="4" width="780" height="25"></rect>

    <circle cx="14" cy="360" r="12" />
    <rect x="50" y="330" rx="4" ry="4" width="280" height="25"></rect>
    <rect x="730" y="330" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="50" y="370" rx="4" ry="4" width="780" height="25"></rect>
  </ContentLoader>
);

export default WebhookLoader;
