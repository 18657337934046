import React, { useState } from "react";

import BouncerContainer from "../../components/shared/BouncerContainer/BouncerContainer";
import { Link } from "react-router-dom";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import bouncerLogo2 from "../../assets/images/bouncer-logo2.svg";
import SignUpForm from "./SignUpForm/SignUpForm";

import "./SignUp.scss";
import AuthAlert from "../../components/shared/AuthAlert/AuthAlert";

const SignUp = () => {
  const [message, setMessage] = useState({
    type: "info",
    title: "",
    subtitle: "",
  });
  const isTablet = useResponsiveDimensions().isTablet;

  return (
    <div className="SignUp">
      {!isTablet && <BouncerContainer />}
      <div className="SignUp__container">
        {isTablet && (
          <div className="SignUp__logo-wrapper">
            <Link to="/" className="SignUp__logo-link">
              <img
                src={bouncerLogo2}
                alt=""
                height="56"
                className="SignUp__logo"
              />
            </Link>
          </div>
        )}
        {message?.title && (
          <AuthAlert
            title={message?.title}
            type={message?.type}
            subtitle={message?.subtitle}
          />
        )}

        <div className="SignUp__wrapper">
          <h1 className="SignUp__title">Create your account</h1>
          <SignUpForm setMessage={setMessage} message={message} />
        </div>

        <div className="SignUp__footer">
          Already have an account?
          <Link to="/login" className="SignUp__footer-link">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
