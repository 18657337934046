import React from "react";

import cs from "classnames";

import "./SwitchButton.scss";

export interface SwitchButtonProps {
  label1?: string;
  label?: string;
  switchState?: boolean;
  onSwitch: () => void;
  disabled?: boolean;
  className?: string;
}

const SwitchButton = (props: SwitchButtonProps) => {
  return (
    <div
      className={cs(
        "SwitchButton",
        props.disabled && `SwitchButton--disabled`,
        props.className
      )}
    >
      {props.label1 && (
        <h6 className="SwitchButton__label" onClick={() => props.onSwitch()}>
          {props.label1}
        </h6>
      )}
      <div
        className={cs(
          "SwitchButton__switch",
          props.switchState && `SwitchButton__switch--active`
        )}
        onClick={() => props.onSwitch()}
      >
        <div
          className={cs(
            "SwitchButton__circle",
            props.switchState && `SwitchButton__circle--active`
          )}
        />
      </div>
      {props.label && (
        <h6 className="SwitchButton__label" onClick={() => props.onSwitch()}>
          {props.label}
        </h6>
      )}
    </div>
  );
};
export default SwitchButton;
