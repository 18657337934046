import React, { useState } from "react";

import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import { useParams, Link } from "react-router-dom";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useNewPasswordFormik } from "./useNewPasswordFormik";
import AuthAlert from "../../../components/shared/AuthAlert/AuthAlert";

import "./NewPassword.scss";

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(true);

  const authCtx = useAuthContext();
  const params = useParams();
  const confirmToken = params?.confirmToken;

  const formik: any = useNewPasswordFormik({
    onSubmit: async (values: any) => {
      if (confirmToken) {
        let cleanToken = confirmToken.replace(/['"]+/g, "");
        authCtx.handleConfirmResetPassword(cleanToken, values.password);
      }
    },
  });

  return (
    <div className="NewPassword">
      <form className="NewPassword__container" onSubmit={formik.handleSubmit}>
        <h3 className="NewPassword__title">Create new password</h3>
        <p className="NewPassword__subtitle">
          Please enter a new password. Your new password must be different from
          previous password.
        </p>
        <div className="NewPassword__input-group-container">
          <div className="NewPassword__input-wrapper">
            <InputField
              type={showPassword ? "password" : "text"}
              className="NewPassword__form-control"
              placeholder="New password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onIconClick={() => {
                setShowPassword(!showPassword);
              }}
              rightIcon={showPassword ? "eye-off" : "visible-password"}
            />
            {formik.errors.password && formik.touched.password && (
              <div className="NewPassword__invalid-feedback--password">
                {formik.errors.password}
              </div>
            )}
          </div>
        </div>
        <div className="NewPassword__input-group-container">
          <div className="NewPassword__input-wrapper">
            <InputField
              type={showConfirmedPassword ? "password" : "text"}
              className="NewPassword__form-control"
              placeholder="Confirm new password"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              onIconClick={() => {
                setShowConfirmedPassword(!showConfirmedPassword);
              }}
              rightIcon={showConfirmedPassword ? "eye-off" : "visible-password"}
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <div className="NewPassword__invalid-feedback">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>
        </div>
        <Link className="NewPassword__login" to="/login">
          Return to login
        </Link>
        {authCtx.message?.title && (
          <AuthAlert
            title={authCtx.message?.title}
            type={authCtx.message?.type}
            subtitle={authCtx.message?.subtitle}
          />
        )}

        <Button
          isLoading={authCtx.isLoading}
          label="Reset Password"
          onClick={() => {}}
          className="Button__primary NewPassword__btn"
          type="submit"
          disabled={
            formik.values.password === "" ||
            formik.values.confirmPassword === "" ||
            formik.values.password !== formik.values.confirmPassword
          }
        />
      </form>
    </div>
  );
};

export default NewPassword;
