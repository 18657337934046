import React, { useEffect, useState } from "react";
import { Icon } from "../../components/shared/Icon/Icon";
import "./Organizations.scss";
import Organization from "./Organization/Organization";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import * as API from "../../api/Api";
import { useLocation } from "react-router-dom";

const Organizations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authCtx = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState(authCtx.organizations);

  useEffect(() => {
    setIsLoading(true);
    API.getOrganizations()
      .then((res) => {
        setIsLoading(false);
        setOrganizations(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "err");
      });
  }, [location.pathname]);

  console.log(organizations, "organizations");

  const handleSelectOrganization = (item: any) => {
    if (item) {
      localStorage.setItem("selectedOrganization", JSON.stringify(item));
      authCtx.setSelectedOrganization(item);
      navigate("/");
    }
  };

  return (
    <div className="Organizations">
      <h1 className="Organizations__title">My Organizations</h1>
      {isLoading ? (
        <div className="Organizations__spinner" />
      ) : (
        <div className="Organizations__container">
          {organizations.map((item: any, index: number) => (
            <Organization
              id={item?.id}
              key={index}
              image={item?.organization_logo_url}
              name={item?.name}
              item={item}
              pickOrganization={handleSelectOrganization}
            />
          ))}
          <div
            className="Organizations__create-project"
            onClick={() => navigate("/create-organization")}
          >
            <div className="Organizations__icon-container">
              <Icon
                onClick={() => {}}
                className="Organizations__icon"
                icon="close"
              />
            </div>
            <p>Create new project</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Organizations;
