import React, { useState, useEffect } from "react";
import Button from "../../../components/shared/Button/Button";
//import Slot from "../../../assets/images/slot.svg";
import Geocode from "react-geocode";
import { Icon } from "../../../components/shared/Icon/Icon";
import QrCode from "../../../assets/images/qr-code.svg";
import * as API from "../../../api/Api";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import Scan from "../../../assets/images/scan.svg";
import QRCode from "qrcode.react";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { useAlertModalContext } from "../../../lib/context/AlertModalContext/AlertModalContext";

import "./Device.scss";

const Device = (props: any) => {
  const { toggle, item, openSideBar } = props;
  const [geoLocation, setGeoLocation] = useState("");
  const [isFlipped, setIsFlipped] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const openMessageModal = useAlertModalContext().openMessageModal;

  Geocode.setApiKey(
    process.env.REACT_APP_GEOCODE_API_KEY
      ? process.env.REACT_APP_GEOCODE_API_KEY
      : ""
  );

  const authCtx = useAuthContext();

  useEffect(() => {
    Geocode.fromLatLng(
      item?.location?.latitude,
      item?.location?.longitude
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setGeoLocation(address);
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  useEffect(() => {
    API.getDeviceQrCode(authCtx.selectedOrganization?.id, item?.id)
      .then((res) => {
        // console.log(res.data);
        setQrValue(res.data.qr_code_data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDownloadQr = async () => {
    const element = document.getElementById("qrCodeSticker");
    if (!element) return;

    const canvas = await html2canvas(element);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${item.name}.png`, "image/png");
  };

  return (
    <div className="Device">
      {isFlipped ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="Device__back"
            onClick={() => {
              setIsFlipped(false);
            }}
          >
            <Icon className="Device__back-icon" icon="chevron-left" />
          </div>

          <div id="qrCodeSticker" style={{ padding: "10px" }}>
            <div className="Device__qr-wrapper">
              <div style={{ padding: "5px" }}>
                <QRCode
                  value={qrValue}
                  size={500}
                  level={"L"}
                  includeMargin={true}
                  fgColor="#1f1e37"
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                />
              </div>
              <div className="Device__scan-wrapper">
                <img src={Scan} alt="" className="Device__scan-with" />
              </div>
            </div>
          </div>
          <div className="Device__buttons-wrapper">
            <Button
              onClick={() => {
                API.simulateScanForDevice(
                  authCtx.selectedOrganization?.id,
                  item?.id
                )
                  .then((res) => {
                    if (res.data?.success) {
                      openMessageModal(
                        "success",
                        `${item?.name} device scan was simulated successfully!`
                      );
                    }
                  })
                  .catch((err) => {
                    openMessageModal(
                      "error",
                      `${
                        err?.response?.data?.error
                          ? err?.response?.data?.error
                          : "An error has occurred, please try again!"
                      }`
                    );
                  });
              }}
              label="Simulate Scan"
              className="Button__primary--outline Device__button Device__button-scan"
            />
            <Button
              onClick={() => {
                handleDownloadQr().then(() => {
                  setIsFlipped(false);
                });
              }}
              label="Download"
              className="Button__primary--outline Device__button"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="Device__content">
            <img
              src={QrCode}
              alt=""
              className="Device__qrcode-icon"
              onClick={() => {
                setIsFlipped(true);
              }}
            />
            <div onClick={toggle}>
              <Icon
                onClick={() => {}}
                className="Device__delete-icon"
                icon="delete"
              />
            </div>
            <div className="Device__image-placeholder">
              {" "}
              {item?.name
                .split(" ")
                .map((word: string) => word.charAt(0))
                .join("")
                .slice(0, 2)}
            </div>
            <h3 className="Device__title">{item?.name}</h3>
            <div className="Device__id"> ID: {item?.id}</div>
            <p className="Device__subtitle">Location: {geoLocation}</p>
            <p className="Device__subtitle2">Minimum age: {item.min_age}</p>
          </div>
          <Button
            onClick={() => openSideBar()}
            label="See Details"
            className="Button__primary--outline Device__button"
          />
        </>
      )}
    </div>
  );
};

export default Device;
