import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { Icon } from "../../../components/shared/Icon/Icon";
import Pagination from "../../../components/shared/Pagination/Pagination";
import { usePaginationContext } from "../../../lib/context/PaginationContext/PaginationContext";
import WebhooksLogLoader from "../../../components/shared/SkeletonLoader/WebhooksLogLoader";

import "./SecureKeyLogs.scss";

const SecureKeyLogs = () => {
  const [allLogs, setAllLogs] = useState<any>([]);
  const [copySuccess, setCopySuccess] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const selectedSecureKeyLog = JSON.parse(
    localStorage.getItem("selectedSecureKeyLog")!!
  );
  const authCtx = useAuthContext();
  const paginationCtx = usePaginationContext();

  const secureKeyLogsPath = `organizations/${authCtx.selectedOrganization?.id}/secure_key/${selectedSecureKeyLog?.id}/logs/?offset=${paginationCtx.offsetNumber}&limit=${paginationCtx.selectedNumber}`;

  useEffect(() => {
    setIsLoading(true);
    API.getSecureKeyLogs(secureKeyLogsPath)
      .then((res) => {
        if (res.status === 200) {
          setAllLogs(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [secureKeyLogsPath]);

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 3000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  return (
    <div className="SecureKeyLogs">
      <div className="SecureKeyLogs__header">
        <h2 className="SecureKeyLogs__header-title">Secure key usage</h2>
      </div>
      <div className="SecureKeyLogs__container">
        {isLoading ? (
          <WebhooksLogLoader />
        ) : (
          <>
            {allLogs.length !== 0 ? (
              <div className="SecureKeyLogs__grid">
                <div className="SecureKeyLogs__log-header">
                  <p>Issued at</p>
                  <p>Expired at</p>
                  <p>Age target</p>
                  <p>Access Token Value</p>
                </div>
                {allLogs?.map((item: any, index: number) => (
                  <div className="SecureKeyLogs__log" key={index}>
                    <div className="SecureKeyLogs__time-wrapper">
                      <div className="SecureKeyLogs__date">
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(item?.iat * 1000))}
                      </div>
                      <div className="SecureKeyLogs__time">
                        {new Date(item?.iat * 1000).getHours()}:
                        {new Date(item?.iat * 1000).getMinutes()}
                      </div>
                    </div>
                    <div className="SecureKeyLogs__time-wrapper">
                      <div className="SecureKeyLogs__date">
                        {" "}
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(item?.exp * 1000))}
                      </div>
                      <div className="SecureKeyLogs__time">
                        {new Date(item?.exp * 1000).getHours()}:
                        {new Date(item?.exp * 1000).getMinutes()}
                      </div>
                    </div>
                    <div className="SecureKeyLogs__age-wrapper">
                      <div className="SecureKeyLogs__age">
                        +{item?.age_target}
                      </div>
                    </div>
                    <div
                      className="flex SecureKeyLogs__token-container"
                      onClick={() => {
                        copyToClipBoard(item?.access_token_value);
                        setSelectedId(item?.session_uuid);
                      }}
                    >
                      <div className="SecureKeyLogs__token-value">
                        {item?.access_token_value}
                      </div>
                      <Icon
                        onClick={() => {}}
                        className="SecureKeyLogs__icon-copy"
                        icon="clipboard"
                      />
                      {copySuccess && item?.session_uuid === selectedId ? (
                        <div className="SecureKeyLogs__copy-message">
                          {" "}
                          {copySuccess}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="SecureKeyLogs__no-content">No logs found!</div>
            )}
          </>
        )}
      </div>
      <div className="SecureKeyLogs__pagination">
        <Pagination />
      </div>
    </div>
  );
};

export default SecureKeyLogs;
