import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import * as API from "../../../api/Api";
import SelectBox2 from "../../../components/shared/SelectBox2/SelectBox2";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import ChartLoader from "../../../components/shared/SkeletonLoader/ChartLoader";
import { Link } from "react-router-dom";

import "./VerificationCountChart.scss";

const VerificationCountChart = (props: any) => {
  const { allDevices, selectedDevice, setSelectedDevice, isLoading2 } = props;
  const d = new Date();
  const currentYear = d.getFullYear();

  const arrayOfYears = Array.from(Array(23), (_, index) => ({
    name: `${currentYear - index}`,
    value: `${currentYear - index}`,
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [maxNumber, setMaxNumber] = useState(0);
  const [selectedTime, setSelectedTime] = useState("Year");
  const [selectedYearFilter, setSelectedYearFilter] = useState({
    name: `${arrayOfYears[0].value}`,
    value: `${arrayOfYears[0].value}`,
  });
  const [selectedMonthFilter, setSelectedMonthFilter] = useState({
    name: "Month",
    value: "",
  });
  const [selectedDayFilter, setSelectedDayFilter] = useState({
    name: "Day",
    value: "",
  });
  const [dayNumber, setDayNumber] = useState<any>();
  const [daysOfAMonth, setDaysOfAMonth] = useState<any>();
  const authCtx = useAuthContext();
  const isMobile = useResponsiveDimensions().isMobile;
  const isTablet = useResponsiveDimensions().isTablet;
  const isBigTablet = useResponsiveDimensions().isBigTablet;

  const yearData = Array.from(Array(12), (_, index) => `${index + 1}/01/2023`);
  const monthsOfYears = [
    { name: "Jan", value: "01", numberOfDays: 31 },
    {
      name: "Feb",
      value: "02",
      numberOfDays:
        new Date(Number(selectedYearFilter?.value), 1, 29).getDate() === 29
          ? 29
          : 28,
    },
    { name: "Mar", value: "03", numberOfDays: 31 },
    { name: "Apr", value: "04", numberOfDays: 30 },
    { name: "May", value: "05", numberOfDays: 31 },
    { name: "Jun", value: "06", numberOfDays: 30 },
    { name: "Jul", value: "07", numberOfDays: 31 },
    { name: "Aug", value: "08", numberOfDays: 31 },
    { name: "Sep", value: "09", numberOfDays: 30 },
    { name: "Oct", value: "10", numberOfDays: 31 },
    { name: "Nov", value: "11", numberOfDays: 30 },
    { name: "Dec", value: "12", numberOfDays: 31 },
  ];

  const hoursInDay = [
    "0:00 - 1:00",
    "1:00 - 2:00",
    "2:00 - 3:00",
    "3:00 - 4:00",
    "4:00 - 5:00",
    "5:00 - 6:00",
    "6:00 - 7:00",
    "7:00 - 8:00",
    "8:00 - 9:00",
    "9:00 - 10:00",
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
    "19:00 - 20:00",
    "20:00 - 21:00",
    "21:00 - 22:00",
    "22:00 - 23:00",
    "23:00 - 24:00",
  ];

  useEffect(() => {
    if (chartData && chartData.length !== 0) {
      let resultMax = Math.max(...chartData);
      setMaxNumber(resultMax + 5);
    }
  }, [chartData]);

  useEffect(() => {
    if (selectedMonthFilter) {
      const monthNumbers = monthsOfYears.filter(
        (item) => item?.value === selectedMonthFilter.value
      );
      setDaysOfAMonth(
        Array.from(
          Array(monthNumbers[0]?.numberOfDays),
          (_, index) =>
            `${selectedMonthFilter?.value}/${index + 1}/${
              selectedYearFilter.value
            }`
        )
      );
      setDayNumber(
        Array.from(Array(monthNumbers[0]?.numberOfDays), (_, index) => ({
          name: index + 1,
          value: `${index + 1}`.padStart(2, "0"),
        }))
      );
    }
  }, [selectedMonthFilter?.value]);

  useEffect(() => {
    if (
      selectedTime === "Year" &&
      selectedDevice?.value &&
      selectedYearFilter?.value
    ) {
      getDataQuery(`year=${selectedYearFilter?.value}`);
    } else if (
      selectedTime === "Month" &&
      selectedDevice?.value &&
      selectedYearFilter?.value &&
      selectedMonthFilter?.value
    ) {
      getDataQuery(
        `year=${selectedYearFilter?.value}&month=${selectedMonthFilter?.value}`
      );
    } else if (selectedTime === "Day" && selectedDevice?.value) {
      getDataQuery(
        `year=${selectedYearFilter?.value}&month=${selectedMonthFilter?.value}&day=${selectedDayFilter?.value}`
      );
    }
  }, [
    selectedTime,
    selectedYearFilter?.value,
    selectedMonthFilter?.value,
    selectedDayFilter?.value,
    selectedDevice?.value,
  ]);

  const getDataQuery = async (path: string) => {
    setIsLoading(true);
    API.getDeviceVerificationCount(
      authCtx.selectedOrganization?.id,
      selectedDevice?.value,
      path
    )
      .then((res) => {
        setChartData(res.data);
        var newPersonsData: any = [];
        res.data?.data.filter((month: any) => newPersonsData.push(month.count));
        setChartData(newPersonsData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const options: any = {
    chart: {
      height: "auto",
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 8,
    },
    markers: {
      size: 8,
    },
    noData: {
      text: "Loading...",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0AB39CD9"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: "500",
            width: "100%",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: "500",
            width: "100%",
          },
        },
      },
    ],
    series: [
      {
        name: "Device verification count",
        data: chartData,
      },
    ],
    title: {
      text: "Device verification count",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "600",
        color: "#1E2945",
      },
    },

    xaxis: {
      type: selectedTime === "Day" ? "category" : "datetime",
      categories:
        selectedTime === "Year"
          ? yearData
          : selectedTime === "Month"
          ? daysOfAMonth
          : selectedTime === "Day"
          ? hoursInDay
          : null,
      labels: {
        offsetY: selectedTime === "Day" ? 10 : 1,
        style: {
          colors: "#ADB5BD",
        },
      },
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      followCursor: true,
      x: {
        format: "MMM yyyy",
        formatter: function (
          value: any,
          { series, seriesIndex, dataPointIndex, w }: any
        ) {
          if (selectedTime === "Year") {
            return new Date(value).toLocaleDateString("en-GB", {
              year: "numeric",
              month: "long",
            });
          }
          if (selectedTime === "Month") {
            return new Date(value).toLocaleDateString("en-GB", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          if (selectedTime === "Day") {
            return `${dataPointIndex}:00 - ${value}:00`;
          }
        },
      },
    },

    yaxis: {
      floating: false,
      max: maxNumber,
      tickAmount: maxNumber,
      labels: {
        maxWidth: 200,
        style: {
          colors: "#ADB5BD",
        },
      },
      title: {
        text: "Device verification count",
        style: {
          color: "#ADB5BD",
          fontSize: "12px",
        },
      },
    },
  };
  return (
    <div className="VerificationCountChart">
      {allDevices?.length !== 0 && (
        <>
          <div className="VerificationCountChart__header">
            <div className="VerificationCountChart__header-buttons">
              {allDevices?.length !== 0 && (
                <>
                  <span className="VerificationCountChart__header-text">
                    Select device:
                  </span>
                  <SelectBox2
                    className="VerificationCountChart__device-selectbox"
                    className2="VerificationCountChart__selectbox-header"
                    items={allDevices}
                    selectedItem={selectedDevice}
                    onItemSelect={(name, value) => {
                      setSelectedDevice({ name: name, value: value });
                      setSelectedMonthFilter({ name: "Month", value: "" });
                      setSelectedDayFilter({ name: "Day", value: "" });
                      setSelectedTime("Year");
                    }}
                  />
                </>
              )}
            </div>
            <div className="VerificationCountChart__header-buttons">
              <span className="VerificationCountChart__header-text">
                View by:
              </span>
              <SelectBox2
                className="VerificationCountChart__selectbox1"
                className2="VerificationCountChart__selectbox-header"
                items={dayNumber}
                selectedItem={selectedDayFilter}
                onItemSelect={(name, value) => {
                  setSelectedDayFilter({ name: name, value: value });
                  setSelectedTime("Day");
                }}
                disabled={!selectedMonthFilter?.value}
                isLoading={isLoading && selectedTime === "Day"}
                active={selectedDayFilter?.value !== ""}
              />
              <SelectBox2
                isLoading={isLoading && selectedTime === "Month"}
                className="VerificationCountChart__selectbox2"
                className2="VerificationCountChart__selectbox-header"
                items={monthsOfYears}
                selectedItem={selectedMonthFilter}
                onItemSelect={(name, value) => {
                  setSelectedMonthFilter({ name: name, value: value });
                  setSelectedTime("Month");
                  setSelectedDayFilter({ name: "Day", value: "" });
                }}
                active={selectedMonthFilter?.value !== ""}
              />
              <SelectBox2
                className="VerificationCountChart__selectbox3"
                className2="VerificationCountChart__selectbox-header"
                items={arrayOfYears}
                selectedItem={selectedYearFilter}
                onItemSelect={(name: any, value: any) => {
                  setSelectedYearFilter({ name: name, value: value });
                  setSelectedMonthFilter({ name: "Month", value: "" });
                  setSelectedDayFilter({ name: "Day", value: "" });
                  setSelectedTime("Year");
                }}
                isLoading={isLoading && selectedTime === "Year"}
                active
              />
            </div>
          </div>
          {isLoading === true || isLoading2 === true ? (
            <div className="VerificationCountChart__loader">
              <ChartLoader
                style={{
                  width: isMobile || isTablet || isBigTablet ? "100%" : 1000,
                }}
              />
            </div>
          ) : (
            <>
              {maxNumber !== undefined && maxNumber <= 5 && !isLoading ? (
                <div className="VerificationCountChart__no-data">
                  <p className="VerificationCountChart__no-data--message">
                    No data found for this <span> selected time</span>
                  </p>
                </div>
              ) : (
                <>
                  <ReactApexChart
                    options={options}
                    series={options?.series}
                    type="line"
                    height="500"
                    width="100%"
                    className="VerificationCountChart__chart"
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      {!allDevices && !isLoading2 && !isLoading && (
        <div className="VerificationCountChart__no-data">
          <p className="VerificationCountChart__no-data--device">
            To see analytics please add{" "}
            <Link to="/devices">
              <span style={{ color: "#61b5e4" }}>device</span>
            </Link>
            .
          </p>
        </div>
      )}
    </div>
  );
};

export default VerificationCountChart;
