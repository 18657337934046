import { useFormik } from "formik";
import * as Yup from "yup";

const editWebhookSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Webhook name is too short!")
    .max(40, "Webhook name is too long!")
    .required("Enter webhook name"),
  description: Yup.string().required("Enter a webhook description"),
});

export const useEditWebhookFormik = (
  data: any,
  opts: {
    onSubmit?: any;
    initialValues?: any;
  }
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: editWebhookSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
