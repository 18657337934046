import { useContext, createContext } from "react";

export interface AuthContextValuesType {
  userError: boolean;
  isAuthenticated: boolean;
  handleLogin: (value: any) => void;
  logOut: () => void;
  isLoading: boolean;
  message: any;
  userData: any;
  getUserData: () => void;
  handleResetPassword: (value: string) => void;
  isLoading2: boolean;
  handleConfirmResetPassword: (value1: string, value2: string) => void;
  organizations: any;
  setSelectedOrganization: any;
  selectedOrganization: any;
  getAllOrganizations: () => void;
  openPopUp: boolean;
  setOpenPopUp: (value: boolean) => void;
}

const AuthContextValues: AuthContextValuesType = {
  userError: false,
  isAuthenticated: false,
  isLoading: false,
  handleLogin: (value: any) => {},
  logOut: () => {},
  message: {},
  userData: {},
  getUserData: () => {},
  handleResetPassword: (value: string) => {},
  isLoading2: false,
  handleConfirmResetPassword: (value1: string, value2: string) => {},
  organizations: [],
  setSelectedOrganization: () => {},
  selectedOrganization: {},
  getAllOrganizations: () => {},
  openPopUp: false,
  setOpenPopUp: () => {},
};

export const AuthContext =
  createContext<AuthContextValuesType>(AuthContextValues);
export const useAuthContext = () => useContext(AuthContext);
