import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/shared/Icon/Icon";

import "./Item.scss";

const Item = (props: any) => {
  const { item, copySuccess, handleCopy } = props;
  const navigate = useNavigate();

  return (
    <div className="Item">
      <div className="Item__main-container">
        <div className="Item__container">
          <div className="flex">
            <h4 className="Item__name">Secure Key ID:</h4>
            <span className="Item__name-id">{item.id}</span>
          </div>
          <div className="Item__icons-wrapper">
            <div
              className="Item__logs-wrapper"
              onClick={() => {
                localStorage.setItem(
                  "selectedSecureKeyLog",
                  JSON.stringify(item)
                );
                navigate("/secure-key/logs");
              }}
            >
              <Icon
                onClick={() => {}}
                className="Item__icon"
                icon="reverse-time"
              />
              <span className="Item__logs-name">View Logs</span>
            </div>
          </div>
        </div>
        <div className="Item__code-container">
          <div className="Item__code-wrapper">
            {" "}
            <code>{item?.value}</code>
          </div>
          <div
            className="Item__copy-container"
            onClick={() => handleCopy(item?.value)}
          >
            <Icon
              onClick={() => {}}
              className="Item__icon-copy"
              icon="clipboard"
            />
            <span className="Item__copy-name">Copy key</span>
            {copySuccess && (
              <div className="Item__copy-message"> {copySuccess} </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
