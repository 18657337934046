import React from "react";
import ContentLoader from "react-content-loader";

const SecureKeyLoader = (props) => (
  <ContentLoader
    speed={4}
    width={1000}
    height={200}
    viewBox="0 0 1000 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="20" y="110" rx="4" ry="4" width="130" height="25"></rect>
    <rect x="160" y="110" rx="4" ry="4" width="200" height="25"></rect>
    <rect x="370" y="110" rx="4" ry="4" width="100" height="25"></rect>
    <rect x="20" y="150" rx="4" ry="4" width="650" height="25"></rect>
    <rect x="700" y="150" rx="4" ry="4" width="100" height="25"></rect>
  </ContentLoader>
);

export default SecureKeyLoader;
