import { ItemsType } from "../components/shared/SelectBox/SelectBox";

export const BILLING_TYPES: Record<number, string> = {
    1: 'FREEMIUM',
    2: 'PREMIUM'
};

export const billingTypesSelectInput: ItemsType[] = [
    {
      name: 'FREEMIUM',
      value: '1',
    },
    {
      name: 'PREMIUM',
      value: '2',
    },
];