import React, { useEffect, useState } from "react";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import InputField from "../../../components/shared/InputField/InputField";
import { useNewWebhookFormik } from "./useNewWebhook";
import SelectBox from "../../../components/shared/SelectBox/SelectBox";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import MultipleSelectBox from "../../../components/shared/MultipleSelectBox/MultipleSelectBox";
import * as API from "../../../api/Api";
import { useAlertModalContext } from "../../../lib/context/AlertModalContext/AlertModalContext";

import "./NewWebhook.scss";
import { Icon } from "../../../components/shared/Icon/Icon";

const NewWebhook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState({
    name: "POST",
  });
  const [allDevices, setAllDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any>([]);
  const [headerValue, setHeaderValue] = useState({
    key: "",
    value: "",
  });
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const openMessageModal = useAlertModalContext().openMessageModal;

  const webhookMethods: any = [
    {
      name: "POST",
    },
    {
      name: "GET",
    },
    {
      name: "DELETE",
    },
    {
      name: "PUT",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    API.getAllDevicesFromOrganization(authCtx.selectedOrganization?.id)
      .then((res) => {
        setAllDevices(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "err");
        setIsLoading(false);
      });
  }, [authCtx.selectedOrganization?.id]);

  const formik: any = useNewWebhookFormik({
    onSubmit: async (values: any) => {
      setIsLoading(true);
      let deviceIds = selectedDevices.map((item) => item.id);
      const dataWithHader = {
        name: values.name,
        description: values.description,
        method: selectedMethod.name,
        url: values.url,
        secret_key: values.secret_key,
        model: "device",
        action: "on_success",
        target: deviceIds,
        header: Object.fromEntries(headers),
      };
      const dataWithoutHader = {
        name: values.name,
        description: values.description,
        method: selectedMethod.name,
        url: values.url,
        secret_key: values.secret_key,
        model: "device",
        action: "on_success",
        target: deviceIds,
      };

      API.createWebhook(
        authCtx.selectedOrganization?.id,
        headers.length !== 0 ? dataWithHader : dataWithoutHader
      )
        .then((res) => {
          setIsLoading(false);
          navigate("/webhooks");
        })
        .catch((err) => {
          setIsLoading(false);
          openMessageModal(
            "error",
            `${
              err?.response?.data?.error
                ? err?.response?.data?.error
                : "An error has occurred, please try again!"
            }`
          );
          console.error(err);
        });
    },
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setHeaderValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddHeader = (name: string, value: string) => {
    if (name === "" || value === "") {
      setError("To add a header please enter key and value");
      return;
    } else {
      setError("");
      let result = [name, value];
      headers.push(result);
    }
  };

  return (
    <div className="NewWebhook">
      <h3 className="NewWebhook__title">Add Webhook</h3>

      <form className="NewWebhook__form" onSubmit={formik.handleSubmit}>
        <h4 className="NewWebhook__subtitle">Webhook Details</h4>

        <div className="NewWebhook__input-group-row">
          <div className="NewWebhook__input-group-container">
            <div className="NewWebhook__input-label">Name</div>
            <InputField
              type="text"
              className="NewWebhook__form-control"
              name="name"
              value={formik.values.name}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
              onIconClick={() => {}}
            />
            {formik.errors.name && formik.touched.device_name && (
              <div className="NewWebhook__invalid-feedback">
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className="NewWebhook__input-group-container">
            <div className="NewWebhook__input-label">Description</div>
            <InputField
              type="text"
              className="NewWebhook__form-control"
              name="description"
              value={formik.values.description}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
              onIconClick={() => {}}
            />
            {formik.errors.description && formik.touched.description && (
              <div className="NewWebhook__invalid-feedback">
                {formik.errors.description}
              </div>
            )}
          </div>
        </div>
        <div className="NewWebhook__input-group-container">
          <div className="NewWebhook__input-label">Method</div>
          <div className="NewWebhook__form-control">
            <SelectBox
              onItemSelect={(name) => setSelectedMethod({ name: name })}
              onClick={() => {}}
              items={webhookMethods}
              selectedItem={selectedMethod}
            />
          </div>
        </div>
        <div className="NewWebhook__input-group-container">
          <div className="NewWebhook__input-label">URL</div>
          <div className="NewWebhook__subtitle-desc">
            Please note that all webhook URLs must start with https://
          </div>
          <InputField
            type="text"
            className="NewWebhook__form-control"
            name="url"
            value={formik.values.url}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.url && formik.touched.url && (
            <div className="NewWebhook__invalid-feedback">
              {formik.errors.url}
            </div>
          )}
        </div>
        <div className="NewWebhook__input-group-container">
          <div className="NewWebhook__input-label">Secret Key</div>
          <div className="NewWebhook__subtitle-desc">
            Add a secret key to sign the payload of your webhook
          </div>
          <InputField
            type="text"
            className="NewWebhook__form-control"
            name="secret_key"
            value={formik.values.secret_key}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {}}
          />
          {formik.errors.secret_key && formik.touched.secret_key && (
            <div className="NewWebhook__invalid-feedback">
              {formik.errors.secret_key}
            </div>
          )}
        </div>
        <div className="NewWebhook__triggers-wrapper">
          <h4 className="NewWebhook__triggers-subtitle">Headers</h4>
        </div>

        <div className="NewWebhook__input-group-row2">
          <div className="NewWebhook__input-group-container">
            <div className="NewWebhook__input-label">Key</div>
            <InputField
              type="text"
              className="NewWebhook__form-control"
              name="key"
              value={headerValue.key}
              onBlur={() => {}}
              onChange={(e: any) => handleInputChange(e)}
              onIconClick={() => {}}
            />
            {error && (
              <div className="NewWebhook__invalid-feedback">{error}</div>
            )}
          </div>
          <div className="NewWebhook__input-group-container">
            <div className="NewWebhook__input-label">Value</div>
            <InputField
              type="text"
              className="NewWebhook__form-control"
              name="value"
              value={headerValue.value}
              onBlur={() => {}}
              onChange={(e: any) => handleInputChange(e)}
              onIconClick={() => {}}
            />
          </div>
          <div className="NewWebhook__input-group-container">
            <Button
              className={cs("Button", "NewWebhook__add-btn")}
              label="Add"
              onClick={() => {
                handleAddHeader(headerValue.key, headerValue.value);
                setHeaderValue({
                  key: "",
                  value: "",
                });
              }}
              type="button"
            />
          </div>
        </div>

        {headers.length !== 0 &&
          headers.map((item: any, index: number) => (
            <div className="NewWebhook__headers-container" key={index}>
              <div className="NewWebhook__headers">
                {item[0]} : {item[1]}
              </div>
              <Icon
                onClick={() => {
                  let filtered = headers.filter(
                    (header: any) => header[0] !== item[0]
                  );
                  setHeaders(filtered);
                }}
                className="NewWebhook__delete-icon"
                icon="delete"
              />
            </div>
          ))}

        <div className="NewWebhook__triggers-wrapper">
          <h4 className="NewWebhook__triggers-subtitle">Triggers</h4>
          <div className="NewWebhook__subtitle-desc">
            Select for which devices your webhook should trigger
          </div>
        </div>
        <div className="NewWebhook__input-group-container">
          <div className="NewWebhook__input-label">Device</div>
          <div className="NewWebhook__subtitle-desc">
            Select one or more devices
          </div>

          <MultipleSelectBox
            onItemSelect={(name, value) => {
              if (!selectedDevices.find((item: any) => item?.id === value)) {
                setSelectedDevices((prev) => [
                  ...prev,
                  { name: name, id: value },
                ]);
              }
            }}
            onClick={() => {}}
            items={allDevices}
            selectedItem={selectedDevices}
            setSelectedDevices={setSelectedDevices}
          />
        </div>
        <div className="NewWebhook__buttons">
          <Button
            className={cs(
              "Button__primary--outline",
              `NewWebhook__discard-btn`
            )}
            label="Discard"
            onClick={() => navigate("/webhooks")}
            type="button"
          />
          <Button
            isLoading={isLoading}
            className={cs("Button", "NewWebhook__save-btn")}
            label="Save"
            onClick={() => {}}
            type="submit"
            disabled={
              selectedDevices.length === 0 ||
              formik.errors.name !== undefined ||
              formik.errors.description !== undefined ||
              formik.errors.url !== undefined ||
              formik.errors.secret_key !== undefined
            }
          />
        </div>
      </form>
    </div>
  );
};

export default NewWebhook;
