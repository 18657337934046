import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useGetParameterByName from "../../lib/hooks/useGetParameterByName";
import NotificationModal from "../../components/shared/NotificationModal/NotificationModal";
import * as API from "../../api/Api";
import emailVerified from "../../assets/images/email-verified.svg";
import somethingWrong from "../../assets/images/something-wrong.svg";
import linkExpired from "../../assets/images/expired-link.svg";

import "./EmailVerification.scss";

const EmailVerification = () => {
  const [emailVerificationStatus, setEmailVerificationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const userId = useGetParameterByName("user_id");
  const confirmationToken = useGetParameterByName("confirmation_token");

  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      setIsLoading(true);
      API.verifyEmail(userId, confirmationToken)
        .then((response) => {
          // console.log({ response });
          setIsLoading(false);
          setEmailVerificationStatus("success");
        })
        .catch((error) => {
          //  console.log({ error });
          // setEmailVerificationStatus("expired");
          setIsLoading(false);
          setEmailVerificationStatus("failed");
        });
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <div className="EmailVerification">
      {isLoading ? (
        <div className="EmailVerification__spinner" />
      ) : (
        <div className="EmailVerification__container">
          {emailVerificationStatus === "success" && (
            <NotificationModal
              title="Your email has been verified"
              subtitle="You can sign in now with your new account using your credentials."
              buttonLabel="Go to Login"
              handleClick={() => navigate("/login")}
              image={emailVerified}
            />
          )}

          {emailVerificationStatus === "failed" && (
            <NotificationModal
              title="Oops! Something went wrong"
              subtitle="Please try refreshing your page or try again later."
              buttonLabel="Go Back"
              handleClick={() => navigate("/sign-up")}
              image={somethingWrong}
            />
          )}

          {emailVerificationStatus === "expired" && (
            <NotificationModal
              title="The link has expired"
              subtitle="The link has already been used or expired. Please request a new one."
              buttonLabel="Go Back"
              handleClick={() => navigate("/sign-up")}
              image={linkExpired}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
