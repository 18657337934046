import { apiRequest } from "./Api";

export const getOrganizations = async () => {
  return apiRequest<any, any>("get", "organizations/?limit=50&offset=0");
};

export const createOrganization = async (data: any) => {
  return apiRequest<any, any>("post", "organizations/", data);
};

export const updateOrganization = async (organizationId: string, data: any) => {
  return apiRequest<any, any>("put", `organizations/${organizationId}/`, data);
};
