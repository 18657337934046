import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/shared/Button/Button";
import { Icon } from "../../components/shared/Icon/Icon";
import SearchInput from "../../components/shared/SearchInput/SearchInput";
import * as API from "../../api/Api";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import Webhook from "./Webhook/Webhook";
import { useNavigate } from "react-router-dom";
import cs from "classnames";
import MessageModal from "../../components/shared/MessageModal/MessageModal";
import RightSidebar from "../../components/shared/RightSidebar/RightSidebar";
import { useOutsideClickDetection } from "../../lib/hooks/useOutSideClickDetection";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";

import "./Webhooks.scss";
import EditWebhook from "./EditWebhook/EditWebhook";
import WebhookLoader from "../../components/shared/SkeletonLoader/WebhookLoader";

const Webhooks = () => {
  const [allWebhooks, setAllWebhooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterWebhooks, setFilterWebhooks] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [webhookId, setWebhookId] = useState<any>();
  const [openRightSidebar, setOpenSidebar] = useState(false);
  const [singleWebhook, setSingleWebhook] = useState<any>({});

  const authCtx = useAuthContext();
  const navigate = useNavigate();
  const rightSidebarRef = useRef(null);
  const isMobile = useResponsiveDimensions().isMobile;
  const isTablet = useResponsiveDimensions().isTablet;

  const selectedOrganization = JSON.parse(
    localStorage.getItem("selectedOrganization")!!
  );

  const getWebhooks = () => {
    setIsLoading(true);
    API.getAllWebhooks(selectedOrganization?.id)
      .then((res: any) => {
        setAllWebhooks(res.data);
        setFilterWebhooks(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error(err, "err");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getWebhooks();
  }, [selectedOrganization?.id]);

  const deleteWebhook = (id: string) => {
    API.deleteWebhookById(selectedOrganization?.id, id)
      .then((res) => {
        if (res.data?.deleted) {
          getWebhooks();
          setShowModal(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setFilterWebhooks(
      !e.target.value
        ? allWebhooks
        : allWebhooks.filter((item: any) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase())
          )
    );
  };

  const hideRightSidebar = () => {
    setOpenSidebar(false);
    setSingleWebhook({});
  };

  useOutsideClickDetection(rightSidebarRef, hideRightSidebar);

  return (
    <div className="Webhooks">
      {showModal && (
        <MessageModal
          onCancel={() => setShowModal(false)}
          onAccept={() => deleteWebhook(webhookId)}
          toggle={() => setShowModal(!showModal)}
          typeOfModal="danger"
          isOpen={showModal}
          title="Are you sure you want to permanently delete this webhook?"
          description="Once deleted, all data will be lost."
        />
      )}
      <div className="Webhooks__header">
        <p className="Webhooks__header-title"> Webhooks </p>
        <Button
          onClick={() => navigate("/webhooks/add-webhook")}
          label="Add webhook"
          className="Webhooks__header-button"
        />
      </div>

      <div className="Webhooks__container">
        <div className="flex">
          <SearchInput
            placeholder="Search webhooks..."
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch(e);
            }}
          />
          <div className="flex Webhooks__filter-container">
            {" "}
            <Icon
              icon="filter"
              onClick={() => {}}
              className="Webhooks__filter-icon"
            />{" "}
            Filters{" "}
          </div>
        </div>
        {isLoading ? (
          <WebhookLoader
            style={{ width: isMobile || isTablet ? "100%" : 1000 }}
          />
        ) : (
          <>
            {filterWebhooks.length !== 0 ? (
              <div className="Webhooks__grid">
                {filterWebhooks?.map((item: any, index) => (
                  <Webhook
                    item={item}
                    key={index}
                    toggle={() => {
                      setShowModal(!showModal);
                      setWebhookId(item?.id);
                    }}
                    openSideBar={() => {
                      setOpenSidebar(true);
                      setSingleWebhook(item);
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="Webhooks__no-content"> No webhooks found! </div>
            )}
          </>
        )}
      </div>
      {openRightSidebar && (
        <RightSidebar
          onClose={() => {
            setOpenSidebar(!openRightSidebar);
            setSingleWebhook({});
          }}
          sidebarRef={rightSidebarRef}
        >
          <EditWebhook
            onClose={() => setOpenSidebar(!openRightSidebar)}
            refresh={() => getWebhooks()}
            singleWebhook={singleWebhook}
            clearWebhook={() => {
              setSingleWebhook({});
            }}
            organizationId={selectedOrganization?.id}
          />
        </RightSidebar>
      )}
    </div>
  );
};

export default Webhooks;
