import Axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

export async function apiRequest<D = {}, R = unknown>(
  method: "get" | "delete" | "head" | "options" | "post" | "put" | "patch",
  path: string,
  input?: D,
  auth?: any
) {
  let accessToken = localStorage.getItem("access_token")!!;
  const refreshToken = localStorage.getItem("refresh_token")!!;

  if (accessToken !== null) {
    const tokenInfo: any = jwt_decode(accessToken);
    const isExpired = dayjs.unix(tokenInfo.exp).diff(dayjs()) < 1;

    if (isExpired) {
      Axios.post(`${process.env.REACT_APP_API_URL}/users/api/token/refresh/`, {
        refresh: refreshToken,
      })
        .then((res) => {
          if (res.status === 200)
            localStorage.setItem("access_token", res.data.access);
        })
        .catch((err) => {
          if (err) {
            localStorage.clear();
            window.location.reload();
          }
        });
    }
  }

  const res = await Axios.request<R>({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: method,
    data: input,
    auth: auth,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

  return res;
}

export * from "./Devices";
export * from "./User";
export * from "./Organizations";
export * from "./Webhooks";
export * from "./SecureKey";
