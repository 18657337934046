import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/shared/Button/Button";
import { Icon } from "../../components/shared/Icon/Icon";
import SearchInput from "../../components/shared/SearchInput/SearchInput";
import Device from "./Device/Device";
import * as API from "../../api/Api";
import MessageModal from "../../components/shared/MessageModal/MessageModal";
import RightSidebar from "../../components/shared/RightSidebar/RightSidebar";
import NewDeviceForm from "./NewDevice/NewDeviceForm";
import cs from "classnames";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";

import "./Devices.scss";
import { useOutsideClickDetection } from "../../lib/hooks/useOutSideClickDetection";
import DeviceLoader from "../../components/shared/SkeletonLoader/DeviceLoader";
import Device2Loader from "../../components/shared/SkeletonLoader/Device2Loader";
import Device3Loader from "../../components/shared/SkeletonLoader/Device3Loader";

const Devices = () => {
  const [allDevices, setAllDevices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openRightSidebar, setOpenSidebar] = useState(false);
  const [deviceLayout, setDeviceLayout] = useState("grid");
  const [isLoading, setIsLoading] = useState(false);
  const [filterDevices, setFilterDevices] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [singleDevice, setSingleDevice] = useState<any>({});

  const authCtx = useAuthContext();
  const rightSidebarRef = useRef(null);
  const isMobile = useResponsiveDimensions().isMobile;
  const isTablet = useResponsiveDimensions().isTablet;

  const selectedOrganization2 = JSON.parse(
    localStorage.getItem("selectedOrganization")!!
  );

  const accessToken = localStorage.getItem("access_token");

  const hideRightSidebar = () => {
    setOpenSidebar(false);
    setSingleDevice({});
  };

  useOutsideClickDetection(rightSidebarRef, hideRightSidebar);

  //get all devices
  const getAllDevices = () => {
    setIsLoading(true);
    API.getAllDevicesFromOrganization(selectedOrganization2?.id)
      .then((res) => {
        setAllDevices(res.data);
        setFilterDevices(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "err");
        if (err.response.status === 403) {
          setAllDevices([]);
          setFilterDevices([]);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    API.getAllDevicesFromOrganization(selectedOrganization2?.id)
      .then((res) => {
        setAllDevices(res.data);
        setFilterDevices(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "err");
        setIsLoading(false);
      });
  }, [selectedOrganization2?.id, accessToken]);

  //delete device by id from organization
  const deleteDeviceById = () => {
    setIsLoading(true);
    API.deleteDeviceFromOrganization(
      authCtx.selectedOrganization.id,
      singleDevice?.id
    )
      .then((res) => {
        if (res.status === 200) {
          getAllDevices();
          setShowModal(false);
          setIsLoading(false);
          setSingleDevice({});
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setFilterDevices(
      !e.target.value
        ? allDevices
        : allDevices.filter((item: any) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase())
          )
    );
  };

  return (
    <div className="Devices">
      {showModal && (
        <MessageModal
          onCancel={() => setShowModal(false)}
          onAccept={() => deleteDeviceById()}
          toggle={() => setShowModal(!showModal)}
          typeOfModal="danger"
          isOpen={showModal}
          title="Are you sure you want to permanently delete this device?"
          description="Once deleted, all data will be lost."
        />
      )}
      <div className="Devices__header">
        <h2 className="Devices__header-title">Devices</h2>
        <div className="Devices__header-container">
          <div className="Devices__header-layout-icons">
            <div
              className={cs(
                "Devices__icon-wrapper",
                deviceLayout === "list" && "Devices__icon-wrapper--active"
              )}
            >
              <Icon
                onClick={() => setDeviceLayout("list")}
                icon="layout-list"
                className="Devices__header-icon"
              />
            </div>
            <div
              className={cs(
                "Devices__icon-wrapper",
                deviceLayout === "grid" && "Devices__icon-wrapper--active"
              )}
            >
              <Icon
                onClick={() => setDeviceLayout("grid")}
                icon="layout-grid"
                className="Devices__header-icon"
              />
            </div>
            <div
              className={cs(
                "Devices__icon-wrapper",
                deviceLayout === "map" && "Devices__icon-wrapper--active"
              )}
            >
              <Icon
                onClick={() => setDeviceLayout("map")}
                icon="map-icon"
                className="Devices__header-icon"
              />
            </div>
          </div>
          <Button
            onClick={() => setOpenSidebar(true)}
            label="Add device"
            className="Devices__header-button"
          />
        </div>
      </div>
      <div className="Devices__container">
        <div className="flex">
          <SearchInput
            placeholder="Search device..."
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearch(e);
            }}
          />
          <div className="flex Devices__filter-container">
            {" "}
            <Icon
              icon="filter"
              onClick={() => {}}
              className="Devices__filter-icon"
            />{" "}
            Filters{" "}
          </div>
        </div>

        {isLoading ? (
          <>
            {isMobile && <DeviceLoader />}
            {isTablet && (
              <div
                className={
                  isMobile
                    ? "Devices__tablet-loader--gone"
                    : "Devices__tablet-loader"
                }
              >
                <Device2Loader />
              </div>
            )}
            {!isMobile && !isTablet && <Device3Loader />}
          </>
        ) : (
          <>
            {filterDevices?.length !== 0 ? (
              <div className="Devices__grid">
                {" "}
                {filterDevices?.map((item: any) => (
                  <Device
                    showModal={showModal}
                    toggle={() => {
                      setShowModal(!showModal);
                      setSingleDevice(item);
                    }}
                    item={item}
                    openSideBar={() => {
                      setOpenSidebar(true);
                      setSingleDevice(item);
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="Devices__no-content"> No devices found! </div>
            )}
          </>
        )}
      </div>
      {openRightSidebar && (
        <div>
          <RightSidebar
            onClose={() => {
              setOpenSidebar(!openRightSidebar);
              setSingleDevice({});
            }}
            sidebarRef={rightSidebarRef}
          >
            <NewDeviceForm
              onClose={() => setOpenSidebar(!openRightSidebar)}
              refresh={() => getAllDevices()}
              singleDevice={singleDevice}
              clearDevice={() => {
                setSingleDevice({});
              }}
              organizationId={authCtx.selectedOrganization?.id}
            />
          </RightSidebar>
        </div>
      )}
    </div>
  );
};

export default Devices;
