import React, { useState } from "react";

import RadioButton from "../../../components/shared/RadioButton/RadioButton";
import { Icon } from "../../../components/shared/Icon/Icon";
import cs from "classnames";
import { useNavigate } from "react-router-dom";

import "./Webhook.scss";

const Webhook = (props: any) => {
  const { item, toggle, openSideBar } = props;
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="Webhook">
      <RadioButton
        onCheck={() => setIsChecked(!isChecked)}
        checked={isChecked}
      />
      <div className="Webhook__main-container">
        <div className="Webhook__container">
          <h4 className="Webhook__name">{item.name}</h4>
          <div className="Webhook__icons-wrapper">
            <Icon
              onClick={() => openSideBar()}
              className="Webhook__icon"
              icon="pen"
            />
            <div onClick={toggle} className="Webhook__delete-icon-wrapper">
              <Icon
                onClick={() => {}}
                className="Webhook__delete-icon"
                icon="delete"
              />
            </div>
            <div
              className="Webhook__logs-wrapper"
              onClick={() => {
                localStorage.setItem("selectedWebhook", JSON.stringify(item));
                navigate("/webhooks/webhook-logs");
              }}
            >
              <Icon
                onClick={() => {}}
                className="Webhook__icon"
                icon="reverse-time"
              />
              <span className="Webhook__logs-name">View Logs</span>
            </div>
          </div>
          <div className="Webhook__state-wrapper">
            <div
              className={cs(
                "Webhook__state-circle",
                item.active && "Webhook__state-circle--active"
              )}
            />{" "}
            <span
              className={cs(
                "Webhook__state",
                item.active && "Webhook__state--active"
              )}
            >
              {item.active ? "Active" : "Inactive"}
            </span>
          </div>
        </div>

        <div className="Webhook__code-container">
          <code>{item?.url}</code>
        </div>
      </div>
    </div>
  );
};

export default Webhook;
