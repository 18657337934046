import { useFormik } from "formik";
import * as Yup from "yup";

const NewDeviceSchema = Yup.object().shape({
  device_name: Yup.string()
    .min(2, "Device name is too short!")
    .max(40, "Device name is too long!")
    .required("Enter device name"),
  device_type: Yup.string().required("Enter a device type"),
  minimum_age: Yup.string().required("Enter minimum age"),
  location: Yup.string().required("Add a device location"),
});

export const useNewDeviceFormik = (
  opts: {
    onSubmit?: any;
    initialValues?: any;
  }
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: {
      device_name:  "",
      device_type:  "",
      minimum_age:  "",
      location:  "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: NewDeviceSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
